
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import {
    getAllClients,
    addNewClient,
    updateClient,
    deleteClient,
    getTimesheets,
} from "actions/profileActions";
// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";


const dataTable = [
    {
        employee_name: 'Harry Styles',
        role: 'Manager',
        task: 'lorem',
        start_date: '22/02/2020',
        end_date: '22/02/2020'

    }
]

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const TimeSheets = ({ getAllClients, allClients, addNewClient, updateClient, deleteClient, allTimesheets, getTimesheets }) => {
    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic1);
    };



    const [userProfile, setUserProfile] = useState([])



    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)
        console.log(row)


    }


    const setUpdateProfile = (row) => {
        toggleModalClassic2()
        setUserProfile(row)


    }

    // Set Table
    const [sheets, setsheets] = useState([]);


    useEffect(() => {
        getTimesheets();
    }, [])

    useEffect(() => {
        if (allTimesheets.length !== 0) {
            setsheets(allTimesheets.timesheet)


        } else {
            return null
        }
    })


    var [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: ''


    })

    var { fname, lname, address, post_code, mobile_no, phone_no, email, town, password } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })




    const updateStaffSubmit = e => {
        e.preventDefault();

        if (fname === '') {
            fname = userProfile.fname
        }
        if (lname === '') {
            lname = userProfile.lname
        }
        if (address === '') {
            address = userProfile.address
        }
        if (post_code === '') {
            post_code = userProfile.post_code
        }
        if (mobile_no === '') {
            mobile_no = userProfile.mobile_no
        }
        if (phone_no === '') {
            phone_no = userProfile.phone_no
        }
        if (email === '') {
            email = userProfile.email
        }
        if (town === '') {
            town = userProfile.town
        }




        var formData = new FormData();
        formData.append("client_id", userProfile.id);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        updateClient(formData, history)

        setFormData({

            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: ''

        })

    }

    function nameFormatter(cell, row) {


        return (
            <span>   {row.employee.fname + ' ' + row.employee.lname} </span>
        );
    }
    function titleFormatter(cell, row) {


        return (
            <span>   {cell === 1 ? 'Manager' : cell === 2 ? 'Staff' : cell === 3 ? 'Client' : null} </span>
        );
    }

    function iconFormatter(cell, row) {


        return (
            <span>   {cell === 0 ? 'InActive' : 'Active'} </span>
        );
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">



                <Row>
                    <div className="col">
                        <Card>

                            <Container>


                                <ToolkitProvider
                                    data={sheets}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "employee.fname",
                                            text: "Employee Name",
                                            sort: true,
                                            formatter: nameFormatter

                                        },
                                        {
                                            dataField: "employee.role_id",
                                            text: "Role",
                                            sort: true,
                                            formatter: titleFormatter

                                        },
                                        {
                                            dataField: "site.site_name",
                                            text: "Site",
                                            sort: true,
                                        },
                                        {
                                            dataField: "date",
                                            text: "Date",
                                            sort: true,
                                        },
                                        {
                                            dataField: "start_time",
                                            text: "Start Time",
                                            sort: true,
                                        },
                                        {
                                            dataField: "end_time",
                                            text: "End Time",
                                            sort: true,
                                        },
                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                {/* <Link className="btn btn-info btn-sm" to="/admin/add-client">Add New Client</Link> */}

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>


                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.task}
                    </ModalHeader>
                    <ModalBody>
                        {
                            modalClassic1 ? (
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr className="border">
                                            <td className="border-0 font-weight-bold">Employee</td>
                                            <td className="border-0">{userProfile.employee.fname + ' ' + userProfile.employee.lname}</td>
                                            <td className="border-0 font-weight-bold">Email</td>
                                            <td className="border-0">{userProfile.employee.email}</td>

                                        </tr>
                                        <tr className="border">
                                            <td className="border-0 font-weight-bold">Start Date</td>
                                            <td className="border-0">{userProfile.start_date}</td>
                                            <td className="border-0 font-weight-bold">End Date</td>
                                            <td className="border-0">{userProfile.end_date}</td>

                                        </tr>
                                        <tr className="border">
                                            <td className="border-0 font-weight-bold">Created At</td>
                                            <td className="border-0">{userProfile.created_at}</td>
                                            <td className="border-0 font-weight-bold">Status</td>
                                            <td className="border-0">{userProfile.status === 0 ? 'Inactive' : 'Active'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : null
                        }


                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>

                {/* Update Staff Modal */}

                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> First Name </Label>
                                        <Input type="text" placeholder={userProfile.fname} value={fname} name="fname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Last Name </Label>
                                        <Input type="text" placeholder={userProfile.lname} value={lname} name="lname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Email </Label>
                                        <Input type="email" placeholder={userProfile.email} value={email} name="email" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Address </Label>
                                        <Input type="text" placeholder={userProfile.address} value={address} name="address" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Post Code </Label>
                                        <Input type="number" placeholder={userProfile.post_code} value={post_code} name="post_code" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Town </Label>
                                        <Input type="text" placeholder={userProfile.town} value={town} name="town" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Phone No </Label>
                                        <Input type="text" placeholder={userProfile.phone_no} value={phone_no} name="phone_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Contact No </Label>
                                        <Input type="text" placeholder={userProfile.mobile_no} value={mobile_no} name="mobile_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>



                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit" onClick={toggleModalClassic2} >
                                    Update
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>
            </div>

        </>
    );
}
const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    allTimesheets: state.profileReducer.allTimesheets
})

export default connect(mapStateToProps, { getAllClients, updateClient, addNewClient, deleteClient, getTimesheets })(TimeSheets);






















