
/*eslint-disable*/
import { logout } from "actions/authActions";
import logo1 from "assets/img/logo1.png";
import PerfectScrollbar from "perfect-scrollbar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, NavLink, useHistory } from "react-router-dom";
import { Button, Collapse, Nav } from "reactstrap";


var ps;

const Sidebar = (props, { isAuthenticated }) => {
  const history = useHistory()
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  React.useEffect(() => {

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {


      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }

      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);


  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.invisible) return null;
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };



  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const logout = () => {
    console.log('logged ot')
    props.logout();
    history.push('/auth/login-page')
  }
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.invisible) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">

            {prop.clickevent ? (
              prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p className="">{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal " onClick={logout}>{prop.name}</span>
                </>
              )

            ) :
              (
                prop.icon !== undefined ? (
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </>
                ) : (
                  <>
                    <span className="sidebar-mini-icon">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
                )
              )



            }
          </NavLink>
        </li>
      );
    });
  };

  const activeRoute = (routeName) => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  const [updateWidth, setUpdateWidth] = useState(null)
  useEffect(() => {
    window.addEventListener("resize", setUpdateWidth(window.innerWidth));
  }, [updateWidth])

  console.log("props?.user?.pic===>", props?.user?.pic)



  return (
    <>
      <div className="sidebar" data-color={props.backgroundColor}>
        <div className="logo">
          <a

            className="simple-text logo-mini"

          >
          </a>
          <a

            className="simple-text logo-normal"

          >
            <img src={logo1} className="img-fluid" width="120" alt="" />
          </a>
          <div className="navbar-minimize">
            <Button
              outline
              className="btn-round btn-icon"
              color="neutral"
              id="minimizeSidebar"
              onClick={() => props.minimizeSidebar()}
            >
              <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
              <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
            </Button>
          </div>
        </div>

        <div className="sidebar-wrapper" ref={sidebar}>
          <div className="user">
            <div className="photo">
              <img alt="Avatar"
                src={props.isAuthenticated ?
                  (`https://api.eagleiq.online/${props?.user?.pic}`)
                  :
                  null}
              />
            </div>
            <div className="info">
              <a

                data-toggle="collapse"
                aria-expanded={openAvatar}
                onClick={() => setOpenAvatar(!openAvatar)}
              >
                <span>
                  {
                    props.isAuthenticated ? (props.user.fname + ' ' + props.user.lname) : null
                  }
                  <b className="caret" />
                </span>
              </a>
              <Collapse isOpen={openAvatar}>
                <ul className="nav">
                  <li>
                    <Link to="/admin/edit-profile" onClick={(e) => e.preventDefault}>
                      <span className="sidebar-mini-icon">MP</span>
                      <span className="sidebar-normal">My Profile</span>
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <Nav>{createLinks(props.routes)}</Nav>
        </div>
      </div>
    </>
  );
}

Sidebar.defaultProps = {
  routes: [],
  showNotification: false,
  backgroundColor: "blue",
  minimizeSidebar: () => { },
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  showNotification: PropTypes.bool,
  backgroundColor: PropTypes.oneOf([
    "blue",
    "yellow",
    "green",
    "orange",
    "red",
  ]),
  minimizeSidebar: PropTypes.func,
};

const mapStateToProps = state => ({

  isAuthenticated: state.authReducer.isAuthenticated,
  user: state.authReducer.user


})

export default connect(mapStateToProps, { logout })(Sidebar);
