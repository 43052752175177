
import {
    addNewClient
} from "actions/profileActions";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {
    postalCodeErrTxt,
    postalCodeRegex
} from "config/constants";
import {
    useState
} from "react";
import InputMask from 'react-input-mask';
import {
    connect
} from 'react-redux';
import {
    useHistory
} from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Row
} from "reactstrap";



const AddClient = ({ addNewClient, isLoading }) => {
    const history = useHistory();

    const [formData, setFormData] = useState({
        company_name: '',
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: '',
        notes: '',
        division: '',
        service_team: '',
        invoice_date: '',
        visits: '',
        file: [],
        date_signed: '',
        status: '',


    })
    const [errors, setErrors] = useState({})

    const {
        company_name,
        fname,
        lname,
        address,
        post_code,
        mobile_no,
        phone_no,
        email,
        town,
        password,
        notes,
        division,
        service_team,
        invoice_date,
        visits,
        file,
        date_signed,
        status
    } = formData;

    const onChange = e => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]:
                e.target.value
        }))
    }
    const onChange1 = e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    const [image, setImage] = useState([]);

    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    //     if (file) {
    //         const reader = new FileReader();

    //         reader.onloadend = () => {
    //             setImage(reader.result);
    //         };

    //         reader.readAsDataURL(file);
    //     }
    // };

    const handleImageChange = (e) => {
        const files = e.target.files;

        const newFiles = Array.from(files).map(file => ({
            file,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        }));

        setImage(prevFiles => [...prevFiles, ...newFiles]);
        setFormData({ ...formData, file: [...formData.file, ...files] });
    };

    const onSubmit = e => {
        e.preventDefault();

        var formData = new FormData();
        formData.append("company_id", '1');
        formData.append("company_name", company_name);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        formData.append("password", password);
        formData.append("notes", notes);
        formData.append("division", division);
        formData.append("service_team", service_team);
        formData.append("invoice_date", invoice_date);
        formData.append("visits", visits);
        // formData.append("file", file);
        file.forEach((data, index) => {
            formData.append(`file[]`, data);
        });
        formData.append("date_signed", date_signed);
        formData.append("status", status);
        addNewClient(formData, history)

        setFormData({
            company_name: '',
            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: '',
            notes: '',
            division: '',
            service_team: '',
            invoice_date: '',
            visits: '',
            file: '',
            date_signed: '',
            status: ''
        })
    }
    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">Add Client</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                                    <CardTitle tag="h6">Company Details</CardTitle>
                                    <Row>
                                        <Col md="4">
                                            <label>Client Name (The Company Name)</label>
                                            <FormGroup>
                                                <Input type="text" required value={company_name} name="company_name" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Contact First Name</label>
                                            <FormGroup>
                                                <Input type="text" required value={fname} name="fname" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Contact Last Name</label>
                                            <FormGroup>
                                                <Input type="text" required value={lname} name="lname" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Password</label>
                                            <FormGroup>
                                                <Input type="password" required value={password} name="password" onChange={onChange} />
                                            </FormGroup>
                                        </Col>

                                        <Col md="4">
                                            <label>Town</label>
                                            <FormGroup>
                                                <Input type="text" required value={town} name="town" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Post Code</label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    required
                                                    value={post_code}
                                                    name="post_code"
                                                    onChange={onChange}
                                                    mask="aa99 9aa"
                                                    tag={InputMask}
                                                />
                                                <p
                                                    style={{
                                                        fontSize: '10px',
                                                        color: '#D63343'
                                                    }}
                                                >
                                                    {errors?.post_code}
                                                </p>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Email</label>
                                            <FormGroup>
                                                <Input type="email" required value={email} name="email" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Date Signed</label>
                                            <FormGroup>
                                                <Input type="date" required onChange={onChange} name="date_signed" value={date_signed} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Division</label>
                                            <FormGroup>
                                                <select name="division" id="" value={division} required className="form-control" onChange={onChange}>
                                                    <option value="" disabled>Select</option>
                                                    <option value="medical">Medical/Health Sector</option>
                                                    <option value="industry">Industrial Site</option>
                                                    <option value="commercial">Commercial</option>
                                                    <option value="pffice">Office Cleaning</option>
                                                    <option value="institution">Institution</option>
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Status</label>
                                            <FormGroup>
                                                <select name="status" required value={status} onChange={onChange} id="" className="form-control">
                                                    <option value="" disabled>Select</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Pending</option>
                                                    <option value="2">Terminated</option>

                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Choose Files</label>
                                            <FormGroup className="">
                                                <input
                                                    type="file"
                                                    required name="file"
                                                    multiple
                                                    onChange={e => handleImageChange(e)}
                                                    style={{
                                                        opacity: '1',
                                                        position: 'relative'
                                                    }}
                                                    id=""
                                                    accept="image/jpeg, image/png, application/pdf"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="12">
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {image.map((fileItem, index) => (
                                                    <div key={index} style={{ margin: '10px' }}>
                                                        {fileItem.preview ? (
                                                            <img
                                                                src={fileItem.preview}
                                                                alt={`Uploaded Image ${index}`}
                                                                style={{ width: '100px', height: '100px' }}
                                                            />
                                                        ) : (
                                                            <div>
                                                                <span style={{ fontWeight: 'bold' }}>{fileItem.file.name} selected</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>

                                        {/* <Col md="4">
                                            {
                                                image
                                                &&
                                                !file?.name?.includes('.pdf')
                                                &&
                                                <img src={image} alt="Uploaded" style={{ maxWidth: '200px' }} />
                                            }
                                        </Col> */}


                                        <Col md="12">
                                            <label htmlFor="">Notes</label>
                                            <textarea
                                                required id=""
                                                cols="30"
                                                value={notes}
                                                name="notes"
                                                onChange={onChange}
                                                rows="10"
                                                className="form-control border rounded"
                                                style={{
                                                    paddingLeft: '10px'
                                                }}
                                            >

                                            </textarea>
                                        </Col>
                                    </Row>
                                    <CardTitle tag="h6" className="mt-5">Company Contact Details</CardTitle>
                                    <Row>

                                        <Col md="4">
                                            <label>Telephone #</label>
                                            <FormGroup>
                                                <Input
                                                    required
                                                    value={phone_no}
                                                    name="phone_no"
                                                    onChange={onChange}
                                                    type="text"
                                                    mask="0 99 9999 9999"
                                                    tag={InputMask}
                                                    onCopy={(e) => {
                                                        e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Mobile No.</label>
                                            <FormGroup>
                                                <Input
                                                    required
                                                    value={mobile_no}
                                                    name="mobile_no"
                                                    onChange={onChange}
                                                    type="text"
                                                    mask="0 99 9999 9999"
                                                    tag={InputMask}
                                                    onCopy={(e) => {
                                                        e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="12">
                                            <label>Address</label>
                                            <FormGroup>
                                                <Input type="text" required value={address} name="address" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="text-center">
                                        {
                                            isLoading ? (
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <Button className="btn-fill" color="info" type="submit">
                                                    Add Client
                                                </Button>

                                            )
                                        }
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    isLoading: state.profileReducer.isLoading
})

export default connect(mapStateToProps, { addNewClient })(AddClient);
