
import {
    Multiselect
} from 'multiselect-react-dropdown';
import PerfectScrollbar from "perfect-scrollbar";
import React, {
    useEffect,
    useState
} from "react";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search
} from "react-bootstrap-table2-toolkit";
import {
    connect
} from 'react-redux';
import {
    useHistory
} from 'react-router-dom';

import {
    addNewManager,
    addNewStaff,
    deleteStaff,
    getAllSites,
    getAllStaff,
    getAllUsers,
    removeSweetAlert,
    updateManager,
    updateStaff
} from "actions/profileActions";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Button,
    Card,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";
import InputMask from 'react-input-mask';
import {
    postalCodeRegex
} from 'config/constants';
import {
    postalCodeErrTxt
} from 'config/constants';


const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const StaffDatabase = ({
    getAllStaff,
    addNewStaff,
    updateStaff,
    addNewManager,
    deleteStaff,
    getAllUsers,
    allUsers,
    sweetAlert,
    managerAddedMsg,
    managerDeletedMsg,
    staffDeletedMsg,
    updatedStaffMsg,
    staffAddedMsg,
    removeSweetAlert,
    getAllSites,
    allSites,
    updateManager,
    updatedManagerMsg
}) => {



    const [alert, setAlert] = React.useState(null);

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {managerAddedMsg ? managerAddedMsg : managerDeletedMsg ? managerDeletedMsg : updatedManagerMsg ? updatedManagerMsg : staffDeletedMsg ? staffDeletedMsg : updatedStaffMsg ? updatedStaffMsg : staffAddedMsg ? staffAddedMsg : updatedManagerMsg ? updatedManagerMsg : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };

    const history = useHistory();
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
        setFormData({
            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: '',
            role_id: '',
            engagement_date: '',
            dob: '',
            ni_no: '',
            bank: '',
            sort_Code: '',
            sites: employeeSites,
            account_no: '',
            rate_of_pay: '',
            next_of_kin_username: '',
            next_of_kin_email: '',
            attachement: []
        })
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: '',
        role_id: '',
        engagement_date: '',
        dob: '',
        ni_no: '',
        bank: '',
        sort_Code: '',
        sites: employeeSites,
        account_no: '',
        rate_of_pay: '',
        next_of_kin_username: '',
        next_of_kin_email: '',
        attachement: []
    })
    const [errors, setErrors] = useState({})

    const [userProfile, setUserProfile] = useState([])
    const [userProfileSites, setUserProfileSites] = useState([])


    const [staff, setStaff] = useState([])

    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)
    }


    const setUpdateProfile = (row) => {

        toggleModalClassic2()
        setUserProfile(row)
        setUserProfileSites(
            row.sites.map(site =>
                site.site
            )
        )
        setFormData({
            fname: row?.fname,
            lname: row?.lname,
            email: row?.email,
            address: row?.address,
            post_code: row?.post_code,
            town: row?.town,
            phone_no: row?.phone_no,
            mobile_no: row?.mobile_no,
            password: row?.password,
            role_id: row?.role_id,
            engagement_date: row?.engagement_date,
            dob: row?.dob,
            ni_no: row?.ni_no,
            bank: row?.bank,
            sort_Code: row?.sort_code,
            sites: row?.sites?.map(site => site?.site),
            account_no: row?.account_no,
            rate_of_pay: row?.rate_of_pay,
            next_of_kin_username: row?.next_of_kin_username,
            next_of_kin_email: row?.next_of_kin_email,
            attachement: row?.attachments
        })

    }

    var [employeeSites, setEmployeeSites] = useState([])
    var [selectedEmployeeSites, setSelectedEmployeeSites] = useState([])
    const onSelect = (selectedItem, selectedList) => {
        setSelectedEmployeeSites(selectedItem)
    }


    var {
        fname,
        lname,
        address,
        post_code,
        mobile_no,
        phone_no,
        email,
        town,
        password,
        role_id,
        engagement_date,
        dob,
        ni_no,
        bank,
        sort_Code,
        sites,
        account_no,
        rate_of_pay,
        next_of_kin_username,
        next_of_kin_email,
        attachement
    } = formData;

    const onChange = e => {
        if (e.target.name === 'bank') {
            let value = e.target.value
            value = value.replace(/[^A-Za-z]/ig, '')
            setFormData((prev) => ({
                ...prev,
                [e.target.name]:
                    value
            }))
        } else {
            setFormData((prev) => ({
                ...prev,
                [e.target.name]:
                    e.target.value
            }))
        }
    }
    const onChange1 = e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    const [uploadedFile, setUploadedFile] = useState([])
    const [newFiles, setNewFiles] = useState([])
    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    //     if (file) {
    //         const reader = new FileReader();

    //         reader.onloadend = () => {
    //             setUploadedFile(reader.result);
    //         };

    //         reader.readAsDataURL(file);
    //     }
    // };

    const handleImageChange = (e) => {
        const files = e.target.files;

        const newFiles = Array.from(files).map(file => ({
            file,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        }));

        setUploadedFile(prevFiles => [...prevFiles, ...newFiles]);
        setFormData({ ...formData, attachement: [...formData.attachement, ...files] });
    };

    const handleFileChange = (e) => {
        const files = e.target.files;

        const newFiles = Array.from(files).map(file => ({
            file,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        }));

        setUploadedFile(prevFiles => [...prevFiles, ...newFiles]);
        setNewFiles([...newFiles, ...files]);
    };

    const onSubmit = e => {
        e.preventDefault();

        if (selectedEmployeeSites?.length === 0) {
            window.alert('Please assign atleast 1 site to employee')
        } else {
            if (role_id === '2') {
                selectedEmployeeSites.map(site => (employeeSites?.push(site?.id)))
                var raw = {
                    "company_id": "1",
                    "fname": fname,
                    "lname": lname,
                    "title": "Staff Member",
                    "address": address,
                    "town": town,
                    "post_code": post_code,
                    "phone_no": phone_no,
                    "mobile_no": mobile_no,
                    "email": email,
                    "password": password,
                    "engagement_date": engagement_date,
                    "dob": dob,
                    "ni_no": ni_no,
                    "bank": bank,
                    "sort_code": sort_Code,
                    "sites": employeeSites,
                    "next_of_kin_username": next_of_kin_username,
                    "next_of_kin_email": next_of_kin_email,
                    "account_no": account_no,
                    "file": attachement,
                    "rate_of_pay": rate_of_pay,
                    "division": null,
                    "service_team": null,
                    "invoice_date": null,
                    "visits": null,
                }

                addNewStaff(raw, history)
                setFormData({
                    fname: '',
                    lname: '',
                    email: '',
                    address: '',
                    post_code: '',
                    town: '',
                    phone_no: '',
                    mobile_no: '',
                    password: '',
                    role_id: '',
                    engagement_date: '',
                    dob: '',
                    ni_no: '',
                    bank: '',
                    sort_Code: '',
                    sites: employeeSites,
                    account_no: '',
                    rate_of_pay: '',
                    next_of_kin_username: '',
                    next_of_kin_email: '',
                    attachement: []
                })

            } else if (role_id === '1') {
                selectedEmployeeSites.map(site => (employeeSites?.push(site?.id)))

                var raw = {
                    "company_id": "1",
                    "fname": fname,
                    "lname": lname,
                    "title": "Manager",
                    "address": address,
                    "town": town,
                    "post_code": post_code,
                    "phone_no": phone_no,
                    "mobile_no": mobile_no,
                    "email": email,
                    "password": password,
                    "engagement_date": engagement_date,
                    "dob": dob,
                    "ni_no": ni_no,
                    "bank": bank,
                    "sort_Code": sort_Code,
                    "sites": employeeSites,
                    "next_of_kin_username": next_of_kin_username,
                    "next_of_kin_email": next_of_kin_email,
                    "account_no": account_no,
                    "rate_of_pay": rate_of_pay,
                    "file": attachement,
                    "division": null,
                    "service_team": null,
                    "invoice_date": null,
                    "visits": null,
                }
                addNewManager(raw, history)
                setFormData({
                    fname: '',
                    lname: '',
                    email: '',
                    address: '',
                    post_code: '',
                    town: '',
                    phone_no: '',
                    mobile_no: '',
                    password: '',
                    role_id: '',
                    engagement_date: '',
                    dob: '',
                    ni_no: '',
                    bank: '',
                    sort_Code: '',
                    sites: employeeSites,
                    account_no: '',
                    rate_of_pay: '',
                    next_of_kin_username: '',
                    next_of_kin_email: '',
                    attachement: []
                })
            }

        }
        setUploadedFile(null)

    }


    const updateStaffSubmit = e => {
        e.preventDefault();

        if (fname === '') {
            fname = userProfile?.fname
        }
        if (lname === '') {
            lname = userProfile?.lname
        }
        if (address === '') {
            address = userProfile?.address
        }
        if (post_code === '') {
            post_code = userProfile?.post_code
        }
        if (mobile_no === '') {
            mobile_no = userProfile?.mobile_no
        }
        if (phone_no === '') {
            phone_no = userProfile?.phone_no
        }
        if (email === '') {
            email = userProfile?.email
        }
        if (town === '') {
            town = userProfile?.town
        }
        if (password === '') {
            password = userProfile?.password
        }
        if (engagement_date === '') {
            engagement_date = userProfile?.engagement_date
        }
        if (dob === '') {
            dob = userProfile?.dob
        }
        if (ni_no === '') {
            ni_no = userProfile?.ni_no
        }
        if (bank === '') {
            bank = userProfile?.bank
        }
        if (sort_Code === '' || sort_Code === "undefined") {
            sort_Code = userProfile?.sort_Code
        }
        if (sites === '') {
            sites = userProfile?.sites
        }
        if (next_of_kin_username === '') {
            next_of_kin_username = userProfile?.next_of_kin_username
        }
        if (next_of_kin_email === '') {
            next_of_kin_email = userProfile?.next_of_kin_email
        }

        if (account_no === '') {
            account_no = userProfile?.account_no
        }
        if (attachement === '') {
            attachement = userProfile?.attachement
        }


        if (role_id === 2) {


            selectedEmployeeSites?.map(site => (employeeSites?.push(site.id)))

            var raw = {
                "staff_id": JSON.stringify(userProfile?.id),

                "company_id": "1",
                "fname": fname,
                "lname": lname,
                "title": "Staff Member",
                "address": address,
                "town": town,
                "post_code": post_code,
                "phone_no": phone_no,
                "mobile_no": mobile_no,
                "email": email,
                "password": password,
                "engagement_date": engagement_date,
                "dob": dob,
                "ni_no": ni_no,
                "bank": bank,
                "sort_code": sort_Code,
                "sites": employeeSites,
                "next_of_kin_username": next_of_kin_username,
                "next_of_kin_email": next_of_kin_email,
                "account_no": account_no,
                "file": [...attachement, ...newFiles],
                "rate_of_pay": rate_of_pay,
                "division": null,
                "service_team": null,
                "invoice_date": null,
                "visits": null,
            }

            updateStaff(raw, history)
            setFormData({
                fname: '',
                lname: '',
                email: '',
                address: '',
                post_code: '',
                town: '',
                phone_no: '',
                mobile_no: '',
                password: '',
                role_id: '',
                engagement_date: '',
                dob: '',
                ni_no: '',
                bank: '',
                sort_Code: '',
                sites: employeeSites,
                account_no: '',
                rate_of_pay: '',
                next_of_kin_username: '',
                next_of_kin_email: '',
                attachement: []
            })

        } else if (role_id === 1) {
            selectedEmployeeSites.map(site => (employeeSites?.push(site?.id)))

            var raw = {
                "manager_id": JSON.stringify(userProfile?.id),

                "company_id": "1",
                "fname": fname,
                "lname": lname,
                "title": "Manager",
                "address": address,
                "town": town,
                "post_code": post_code,
                "phone_no": phone_no,
                "mobile_no": mobile_no,
                "email": email,
                "password": password,
                "engagement_date": engagement_date,
                "dob": dob,
                "ni_no": ni_no,
                "bank": bank,
                "sort_code": sort_Code,
                "sites": employeeSites,
                "next_of_kin_username": next_of_kin_username,
                "next_of_kin_email": next_of_kin_email,
                "account_no": account_no,
                "file": [...attachement, ...newFiles],
                "rate_of_pay": rate_of_pay,
                "division": null,
                "service_team": null,
                "invoice_date": null,
                "visits": null,
            }
            updateManager(raw, history)
            setFormData({
                fname: '',
                lname: '',
                email: '',
                address: '',
                post_code: '',
                town: '',
                phone_no: '',
                mobile_no: '',
                password: '',
                role_id: '',
                engagement_date: '',
                dob: '',
                ni_no: '',
                bank: '',
                sort_Code: '',
                sites: employeeSites,
                account_no: '',
                rate_of_pay: '',
                next_of_kin_username: '',
                next_of_kin_email: '',
                attachement: []
            })
        } else {
            window.alert("not updated")
        }
    }

    function iconFormatter(cell, row) {


        return (
            <span>   {cell === 0 ? 'InActive' : 'Active'} </span>
        );
    }
    function titleFormatter(cell, row) {


        return (
            <span>   {cell === 1 ? 'Manager' : cell === 2 ? 'Staff' : cell === 3 ? 'Client' : null} </span>
        );
    }

    useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {

            hideAlert()
        }
    }, [sweetAlert])

    useEffect(() => {

        getAllUsers();
        getAllStaff();
        getAllSites()


        var container = document.getElementById('scroll-bar');
        var ps = new PerfectScrollbar(container)
    }, [])


    useEffect(() => {

        if (allUsers.length !== 0) {

            const newstaff = allUsers?.AllUsers?.filter((staff) => {
                return (staff?.role_id === 1 || staff?.role_id === 2)
            })
            setStaff(newstaff?.reverse())


        } else {
            return null
        }



    }, [allUsers])

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}


                <Row>
                    <div className="col">
                        <Card className="p-3">

                            <ToolkitProvider
                                data={staff}
                                keyField="id"
                                bootstrap4

                                columns={[
                                    {
                                        dataField: "fname",
                                        text: "First Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "lname",
                                        text: "Last Name",
                                        sort: true,
                                    },
                                    {
                                        dataField: "role_id",
                                        text: "Title",
                                        sort: true,
                                        formatter: titleFormatter


                                    },

                                    {
                                        dataField: "email",
                                        text: "Email",
                                        sort: true,
                                    },
                                    {
                                        dataField: "phone_no",
                                        text: "Contact",
                                        sort: true,
                                    },
                                    {
                                        dataField: "status",
                                        text: "Status",
                                        sort: true,
                                        formatter: iconFormatter
                                    },

                                    {
                                        dataField: 'df2',
                                        isDummyField: true,
                                        text: 'Action',
                                        formatter: (cellContent, row) => {
                                            return (
                                                <h5 className="d-flex flex-wrap justify-content-center">
                                                    <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)} > <i className="fas fa-eye"></i></span>
                                                    <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateProfile(row)} > <i className="fas fa-edit"></i></span>


                                                    <span className="btn btn-danger btn-sm m-1" onClick={() => deleteStaff(row.id, history)}> <i className="fas fa-times"></i></span>
                                                </h5>
                                            );

                                        }
                                    }


                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive" id="scroll-bar">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                        >

                                            <label>
                                                Search:
                                                <SearchBar
                                                    className="form-control"
                                                    placeholder=""
                                                    {...props.searchProps}
                                                />
                                            </label>
                                            <Button size="sm " color='info' onClick={toggleModalClassic}>Add Staff</Button>

                                        </div>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>




                        </Card>

                    </div>


                </Row>
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="xl"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title"
                        toggle={toggleModalClassic}
                        tag="h4"
                    >
                        Add Staff
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                            <Row>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>First Name </label>

                                        <Input type="text"
                                            name="fname"
                                            value={fname}
                                            onChange={onChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Last Name</label>

                                        <Input type="text"
                                            name="lname"
                                            value={lname}
                                            onChange={onChange} required
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Town</label>

                                        <Input type="text" name="town"
                                            value={town}
                                            onChange={onChange}
                                            required
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Postal Code</label>
                                        <Input
                                            type="text"
                                            name="post_code"
                                            value={post_code}
                                            onChange={onChange}
                                            mask="aa99 9aa"
                                            tag={InputMask}
                                            required
                                        />
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                color: '#D63343'
                                            }}
                                        >
                                            {errors?.post_code}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Address</label>

                                        <Input type="text" name="address"
                                            value={address} required
                                            onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Telephone</label>
                                        <Input
                                            name="phone_no"
                                            value={phone_no}
                                            onChange={onChange}
                                            required
                                            type="text"
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>
                                            Contact Number
                                        </label>
                                        <Input
                                            name="mobile_no"
                                            value={mobile_no}
                                            onChange={onChange}
                                            required
                                            type="text"
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Email</label>


                                        <Input type="email" name="email"
                                            value={email}
                                            onChange={onChange} required />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Password</label>

                                        <Input type="password" name="password"
                                            value={password}
                                            onChange={onChange} required />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Role</label>


                                        <select name="role_id" required type="select" value={role_id} onChange={onChange} className="form-control">
                                            <option value="">Select</option>
                                            <option value="1">Manager</option>
                                            <option value="2">Staff</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Engagement Date</label>

                                        <Input type="date" required name="engagement_date" value={engagement_date} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>DOB</label>

                                        <Input type="date" required name="dob" value={dob} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>NI. Number</label>
                                        <Input
                                            required
                                            name="ni_no"
                                            value={ni_no}
                                            onChange={onChange}
                                            type="text"
                                            mask="aa-999999-a"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Assigned Sites</label>
                                        <Multiselect
                                            options={allSites}
                                            isObject={true}
                                            required
                                            displayValue="site_name"
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            avoidHighlightFirstOption={true}
                                            showArrow={true}
                                            onSelect={onSelect}
                                            onRemove={onSelect}
                                            className="form-control"

                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>File Upload</label>

                                        <FormGroup className="">
                                            <input type="file" required name="attachement"
                                                // data-allowed-file-extensions='["pdf", "doc"]' 
                                                accept="image/jpeg, image/png, application/pdf"
                                                multiple
                                                onChange={e => handleImageChange(e)}
                                                style={{ opacity: '1', position: 'relative' }}
                                            />
                                        </FormGroup>
                                    </FormGroup>
                                </Col>
                                {/* {
                                    uploadedFile !== null
                                    &&
                                    !attachement?.name?.includes('.pdf')
                                    &&
                                    <Col Col md="12">
                                        <img
                                            src={uploadedFile}
                                            alt="Profile"
                                            style={{
                                                width: '200px'
                                            }}
                                        />
                                    </Col>
                                } */}
                                <Col md="12">
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {uploadedFile?.map((fileItem, index) => (
                                            <div key={index} style={{ margin: '10px' }}>
                                                {fileItem.preview ? (
                                                    <img
                                                        src={fileItem.preview}
                                                        alt={`Uploaded Image ${index}`}
                                                        style={{ width: '100px', height: '100px' }}
                                                    />
                                                ) : (
                                                    <div>
                                                        <span style={{ fontWeight: 'bold' }}>{fileItem.file.name} selected</span>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                                <hr />
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Bank</label>
                                        <Input
                                            type="text"
                                            required
                                            value={bank}
                                            name="bank"
                                            onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Sort Code</label>
                                        <Input
                                            required
                                            value={sort_Code}
                                            name="sort_Code"
                                            onChange={onChange}
                                            type="text"
                                            mask="99-99-99"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Account Number</label>


                                        <Input type="number" required value={account_no} name="account_no" onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Pay Rate</label>
                                        <div style={{ position: "relative" }}>

                                            <span style={{ position: "absolute", left: "20px", top: "5px" }}>&#163;</span>
                                            <Input type="number" style={{ paddingLeft: "35px" }} required value={rate_of_pay} name="rate_of_pay" onChange={onChange}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <h5>Next of kin</h5>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Name</label>
                                        <Input type="text" required value={next_of_kin_username} name="next_of_kin_username" onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Email</label>
                                        <Input type="text" required value={next_of_kin_email} name="next_of_kin_email" onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <div className="text-center">
                                <Button color="info" type="submit" >
                                    Add
                                </Button>
                            </div>

                        </Form>
                    </ModalBody>


                </Modal>


                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile?.fname + ' ' + userProfile?.lname}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">First Name</td>
                                    <td className="border-0">{userProfile?.fname}</td>
                                    <td className="border-0 font-weight-bold">Last Name</td>
                                    <td className="border-0">{userProfile?.lname}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Role</td>
                                    <td className="border-0">{userProfile?.role_id === 1 ? ('Manager') : userProfile?.role_id === 2 ? ('Staff') : null}</td>
                                    <td className="border-0 font-weight-bold">Last Name</td>
                                    <td className="border-0">{userProfile?.lname}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Email</td>
                                    <td className="border-0">{userProfile?.email}</td>
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{userProfile?.address}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Phone No</td>
                                    <td className="border-0">{userProfile?.phone_no}</td>
                                    <td className="border-0 font-weight-bold">Post Code</td>
                                    <td className="border-0">{userProfile?.post_code}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Assigned Sites</td>
                                    <td className="border-0">
                                        {
                                            userProfile?.sites?.map((site, index, array) => (
                                                <span key={site?.site?.site_name}>
                                                    {site?.site?.site_name}
                                                    {index < array.length - 1 && ','}
                                                </span>
                                            ))
                                        }
                                    </td>
                                    <td className="border-0 font-weight-bold">File</td>
                                    <td className="border-0">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                                userProfile?.attachments?.map(data => (
                                                    <div
                                                        key={data?.id}
                                                        style={{
                                                            margin: '4px'
                                                        }}
                                                    >
                                                        {
                                                            data?.path?.includes('.pdf')
                                                                ?
                                                                <a target="_blank" href={`https://api.eagleiq.online/${data?.path}`}>See Attachment</a>
                                                                :
                                                                <img
                                                                    src={`https://api.eagleiq.online/${data?.path}`}
                                                                    alt="Profile"
                                                                    style={{ width: '80px', height: '80px' }}
                                                                />
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>


                {/* Update Staff Modal */}

                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        {userProfile?.fname + ' ' + userProfile?.lname}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> First Name </Label>
                                        <Input type="text" placeholder={userProfile?.fname} value={fname} name="fname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Last Name </Label>
                                        <Input type="text" placeholder={userProfile?.lname} value={lname} name="lname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Email </Label>
                                        <Input type="email" placeholder={userProfile?.email} value={email} name="email" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Address </Label>
                                        <Input type="text" placeholder={userProfile?.address} value={address} name="address" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold">
                                            Postal Code
                                        </Label>
                                        <Input
                                            placeholder={
                                                userProfile
                                                    ?.post_code
                                            }
                                            type="text"
                                            value={post_code}
                                            name="post_code"
                                            onChange={onChange}
                                            mask="aa99 9aa"
                                            tag={InputMask}
                                        />
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                color: '#D63343'
                                            }}
                                        >
                                            {errors?.post_code}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Town </Label>
                                        <Input type="text" placeholder={userProfile?.town} value={town} name="town" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold">
                                            Telephone
                                        </Label>
                                        <Input
                                            placeholder={userProfile?.phone_no}
                                            value={phone_no}
                                            name="phone_no"
                                            onChange={onChange}
                                            type="text"
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label
                                            className="text-left font-weight-bold"
                                        >
                                            Contact Number
                                        </Label>
                                        <Input
                                            placeholder={
                                                userProfile
                                                    ?.mobile_no
                                            }
                                            value={mobile_no}
                                            name="mobile_no"
                                            onChange={onChange}
                                            type="text"
                                            mask="0 99 9999 9999"
                                            tag={InputMask}
                                            onCopy={(e) => {
                                                e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                e.preventDefault();
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Role</label>


                                        <select name="role_id" type="select" required value={role_id} onChange={onChange} className="form-control">
                                            <option value="">Select</option>
                                            <option value="1">Manager</option>
                                            <option value="2">Staff</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Engagement Date</label>

                                        <Input type="date" name="engagement_date" placeholder={userProfile?.engagement_date} value={engagement_date} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>DOB</label>

                                        <Input type="date" name="dob" value={dob} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>NI. Number</label>

                                        <Input
                                            name="ni_no"
                                            placeholder={userProfile?.ni_no}
                                            value={ni_no}
                                            onChange={onChange}
                                            type="text"
                                            mask="aa-999999-a"
                                            tag={InputMask}
                                            formatChars={{
                                                "A": "[A-Za-z]",
                                                "9": "[0-9]",
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Assigned Sites</label>
                                        <Multiselect
                                            options={allSites}
                                            isObject={true}
                                            selectedValues={userProfileSites}
                                            displayValue="site_name"
                                            showCheckbox={true}
                                            closeOnSelect={false}
                                            avoidHighlightFirstOption={true}
                                            showArrow={true}
                                            onSelect={onSelect}
                                            onRemove={onSelect}
                                            className="form-control"

                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>File Upload</label>

                                        <FormGroup className="">
                                            <input
                                                type="file"
                                                name="attachement"
                                                multiple
                                                accept="image/jpeg, image/png, application/pdf"
                                                onChange={e => handleFileChange(e)}
                                                style={{ opacity: '1', position: 'relative' }}
                                            />
                                            {/* <span>{userProfile?.attachment?.split("/")[2]}</span> */}
                                            {/* {
                                                userProfile?.attachment?.includes('.pdf')
                                                &&
                                                uploadedFile === null
                                                &&
                                                <a target="_blank" href={`https://api.eagleiq.online/${userProfile?.attachment}`}>See Attachment</a>
                                            } */}
                                        </FormGroup>
                                    </FormGroup>
                                    {/* {
                                        uploadedFile === null && !userProfile?.attachment?.includes('.pdf')
                                            ? (
                                                <img
                                                    src={`https://api.eagleiq.online/${userProfile?.attachment}`}
                                                    alt="Profile"
                                                    width={200}
                                                />
                                            )
                                            :
                                            uploadedFile != null && !attachement?.name?.includes('.pdf')
                                                ? (
                                                    <img
                                                        src={uploadedFile}
                                                        alt="Profile"
                                                        width={200}
                                                    />
                                                )
                                                :
                                                null
                                    } */}
                                </Col>
                                <Col md="12">
                                    {
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {userProfile?.attachments?.map((fileItem, index) => (
                                                <div key={index} style={{ margin: '10px' }}>
                                                    {!fileItem.filename.includes('.pdf') ? (
                                                        <img
                                                            src={`https://api.eagleiq.online/${fileItem.path}`}
                                                            alt={`Uploaded Image ${index}`}
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    ) : (
                                                        <div>
                                                            <a href={`https://api.eagleiq.online/${fileItem.path}`} target="_blank">See attachment</a>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {uploadedFile?.map((fileItem, index) => (
                                                <div key={index} style={{ margin: '10px' }}>
                                                    {fileItem.preview ? (
                                                        <img
                                                            src={fileItem.preview}
                                                            alt={`Uploaded Image ${index}`}
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    ) : (
                                                        <div>
                                                            <span style={{ fontWeight: 'bold' }}>{fileItem.file.name} selected</span>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </Col>
                                <hr />
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Bank</label>

                                        <Input type="text" value={bank} name="bank" placeholder={userProfile?.bank} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Sort Code</label>

                                        <Input
                                            value={sort_Code}
                                            name="sort_Code"
                                            placeholder={userProfile?.sort_code}
                                            onChange={onChange}
                                            type="text"
                                            mask="99-99-99"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Account Number</label>


                                        <Input type="number" value={account_no} name="account_no" placeholder={userProfile?.account_no} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Pay Rate</label>


                                        <Input type="number" value={rate_of_pay} name="rate_of_pay" placeholder={userProfile?.rate_of_pay} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <h5>Next of kin</h5>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Name</label>
                                        <Input type="text" value={next_of_kin_username} name="next_of_kin_username" placeholder={userProfile?.next_of_kin_username} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup className="text-left">
                                        <label>Email</label>
                                        <Input type="text" value={next_of_kin_email} name="next_of_kin_email" placeholder={userProfile?.next_of_kin_email} onChange={onChange}
                                        />
                                    </FormGroup>
                                </Col>





                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit"  >
                                    Update
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>
            </div >

        </>
    );
}

const mapStateToProps = state => ({
    auth: state?.authReducer,
    allStaff: state?.profileReducer?.allStaff,
    allUsers: state?.profileReducer?.allUsers,
    newManager: state?.profileReducer?.newManager,
    isLoading: state?.profileReducer?.isLoading,
    managerAddedMsg: state?.profileReducer?.newManager?.message,
    managerDeletedMsg: state?.profileReducer?.deletedManager?.message,
    updatedManagerMsg: state?.profileReducer?.updatedManager?.message,
    staffAddedMsg: state?.profileReducer?.newStaff.message,
    staffDeletedMsg: state?.profileReducer?.deletedStaff.message,
    updatedStaffMsg: state?.profileReducer?.updatedStaff.message,
    managerUpdatedMsg: state?.profileReducer?.updatedManager.message,
    sweetAlert: state?.profileReducer?.sweetAlert,
    allSites: state?.profileReducer?.allSites?.sites
})

export default connect(
    mapStateToProps,
    {
        getAllStaff,
        addNewStaff,
        updateStaff,
        deleteStaff,
        getAllUsers,
        addNewManager,
        removeSweetAlert,
        getAllSites,
        updateManager,
    }
)(StaffDatabase);






















