
import React from "react";
import Select from "react-select";
import { Link } from 'react-router-dom'

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

var selectOptions = [
    { value: "one", label: "One" },
    { value: "two", label: "Two" },
    { value: "three", label: "Three" },
    { value: "four", label: "Four" },
    { value: "five", label: "Five" },
    { value: "six", label: "Six" },
];
function AddNewTask() {
    const [singleSelect, setSingleSelect] = React.useState(null);
    const [multipleSelect, setMultipleSelect] = React.useState(null);
    const [multipleSelect1, setMultipleSelect1] = React.useState(null);
    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col md="8" className="mr-auto ml-auto">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Add New Job</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form action="#" method="#">
                                    <label>Client</label>
                                    <FormGroup>
                                        <Select
                                            className="react-select warning"
                                            classNamePrefix="react-select"

                                            closeMenuOnSelect={false}
                                            placeholder="Select Client"
                                            name="multipleSelect"
                                            value={multipleSelect}
                                            options={selectOptions}
                                            onChange={(value) => setMultipleSelect(value)}
                                        />
                                    </FormGroup>
                                    <label>Site</label>
                                    <FormGroup>
                                        <Select
                                            className="react-select warning"
                                            classNamePrefix="react-select"

                                            closeMenuOnSelect={false}
                                            placeholder="Select Site"
                                            name="multipleSelect"
                                            value={multipleSelect1}
                                            options={selectOptions}
                                            onChange={(value) => setMultipleSelect1(value)}
                                        />
                                    </FormGroup>

                                </Form>
                            </CardBody>
                            <CardFooter className="text-right">

                                <Link to="/admin/add-new-area" className="btn btn-info">Next</Link>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default AddNewTask;
