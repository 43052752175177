
import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Editor } from '@tinymce/tinymce-react';
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

// var selectOptions = [
//     { value: "one", label: "One" },
//     { value: "two", label: "Two" },
//     { value: "three", label: "Three" },
//     { value: "four", label: "Four" },
//     { value: "five", label: "Five" },
//     { value: "six", label: "Six" },
// ];

const createOption = (label) => ({
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});
var selectOptions = [
    createOption('Monday'),
    createOption('Tuesday'),
    createOption('Wednesday'),
    createOption('Thursday'),
    createOption('Friday'),
    createOption('Saturday'),
    createOption('Sunday'),
]

const AddNewArea = () => {
    // ------ Perfect Scroll Bar -------------

    React.useEffect(() => {
        const container = document.querySelector('#scroll-bar');
        console.log(container)
        // var ps = new PerfectScrollbar(container);

    });

    // ------ Perfect Scroll Bar End -------------
    // ----------------  Tiny Editor

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    // ---------- Tiny Editor End -----------
    const addNewOption = (value) => {
        console.log(value)
    }
    const [singleSelect, setSingleSelect] = React.useState(null);
    const [multipleSelect, setMultipleSelect] = React.useState(null);
    const [multipleSelect1, setMultipleSelect1] = React.useState(null);
    const [updateTabName, setUpdatedTabName] = React.useState(null);

    const [singleFileName, setSingleFileName] = React.useState("");
    const [singleFile, setSingleFile] = React.useState(null);

    const singleFileRef = React.useRef();
    const handleSingleFileInput = (e) => {
        singleFileRef.current.click(e);
    };
    const addSingleFile = (e, type) => {
        let fileNames = "";
        let files = e.target.files;
        for (let i = 0; i < e.target.files.length; i++) {
            fileNames = fileNames + e.target.files[i].name;
        }
        setSingleFile(files);
        setSingleFileName(fileNames);
    };

    // Tabs Stiff

    const [tabs, setTabs] = React.useState([
        { id: '1', name: "Area 1", Content: "This is Area 1" },
        { id: '2', name: "Area 2", Content: "This is Area 2" },
    ]);

    const [editTabNameMode, setEditTabNameMode] = useState(false)
    const [currentTab, setCurrentTab] = React.useState({ id: '1', name: 'Area 1', Content: 'This is Area 1' })
    const [doubleClickedTab, setdoubleClickedTab] = React.useState(null)

    const addTab = () => {
        const newObject = {
            id: `${tabs.length + 1}`,
            name: `Area ${tabs.length + 1}`,
            Content: `This is Area ${tabs.length + 1}`
        }

        setTabs([...tabs, newObject])
    }
    const handleDoubleClick = (tab) => {

        if (updateTabName) {
            tab.name = updateTabName
            console.log(tab.name)

            if (tab.id === currentTab.id) {
                setTabs([...tabs])
            } else {
                return tab;
            }
            console.log(currentTab.id)
            setUpdatedTabName('')
            console.log(tabs)
        } else {
            window.alert('Area name cannot be empty')
        }



    };


    const handleSelectTab = tab => {

        setCurrentTab(tab)
        setdoubleClickedTab(tab)
    };

    const onSubmit = (e) => {
        e.preventDefault()
        if (doubleClickedTab) {
            handleDoubleClick(doubleClickedTab)



        } else {
            window.alert('Please select a tab to rename')

        }


    }




    //  ------------------------




    // const createOption = (label) => {
    //     label
    //     // value: label.toLowerCase().replace(/\W/g, ''),
    //     setValue(label.toLowerCase())
    // }

    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(undefined)
    const [options, setOptions] = useState(selectOptions)

    const handleChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        // this.setState({ value: newValue });
        setValue(newValue)
    };


    // const handleCreate = (inputValue) => {
    //     setIsLoading(true)
    //     console.group('Option created');
    //     console.log('Wait a moment...');
    //     setTimeout(() => {
    //         // const { selectOptions } = this.state;
    //         const newOption = createOption(inputValue);

    //         console.log(newOption);
    //         console.groupEnd();

    //         setIsLoading(false)
    //         setOptions(...options, newOption)
    //         setValue(newOption)
    //         console.log(options)



    //     }, 1000);
    // };


    // Duplicator Function  ------------------




    const duplicate = () => {
        var i = 0;


        var original = document.getElementById('duplicater');
        console.log(original)

        var clone = original.cloneNode(true); // "deep" clone
        clone.id = "duplicater" + ++i;
        // or clone.id = ""; if the divs don't need an ID
        original.parentNode.appendChild(clone);
        console.log(original)
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col md="8" className="mr-auto ml-auto">
                        <Card>
                            <CardHeader className="d-flex justify-content-between align-items-center">
                                <CardTitle tag="h4">Add New Job</CardTitle>
                                {/* <Button color="info" size="sm">Upload a File</Button> */}
                                <FormGroup className="form-file-upload form-file-simple">
                                    <Input
                                        type="text"
                                        className="inputFileVisible btn btn-info bg-info white-input-field text-white rounded"
                                        placeholder="Upload File"
                                        onClick={(e) => handleSingleFileInput(e)}
                                        defaultValue={singleFileName}
                                        readOnly

                                    />
                                    {/* <Button color="info" size="sm" onClick={(e) => handleSingleFileInput(e)}>Upload a File</Button> */}
                                    <input
                                        type="file"
                                        className="inputFileHidden"
                                        style={{ zIndex: -1 }}
                                        ref={singleFileRef}
                                        onChange={(e) => addSingleFile(e)}
                                    />
                                </FormGroup>

                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={onSubmit} className="form-horizontal">
                                    <Row className="">
                                        <Col md="10">
                                            <FormGroup>
                                                <Input placeholder="Add a Title" value={updateTabName} id="title_field" className="mb-3" onChange={(e) => setUpdatedTabName(e.target.value)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Button size="sm" type="submit" color="info">Rename</Button>

                                        </Col>
                                    </Row>


                                </Form>


                                <Tabs>

                                    <TabList>
                                        {
                                            tabs.map(tab => (
                                                <Tab onDoubleClick={() => handleDoubleClick(tab)} onClick={() => handleSelectTab(tab)}  >{tab.name}</Tab>
                                            ))
                                        }


                                        <i className="fas fa-plus-circle ml-3 text-info" style={{ cursor: 'pointer' }} onClick={() => addTab()}></i>


                                    </TabList>

                                    {
                                        tabs.length === 0 || tabs.length === undefined ? null : (
                                            tabs.map(tab => (
                                                <TabPanel id="" style={{ height: '300px', position: 'relative', overflowX: 'hidden', overflowY: 'auto' }}>
                                                    <Row id="duplicater">

                                                        <Col md="8">
                                                            <label htmlFor="">Task </label>
                                                            <FormGroup className="editor-section">
                                                                <Editor
                                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                                    apiKey='ig44t7sd2d9z3w3i5xaq9rrm9x3gvwhujfrxt0y3wlx8uxya'
                                                                    initialValue="<p>Add Task Here.</p>"
                                                                    init={{
                                                                        height: 200,
                                                                        menubar: false,
                                                                        selector: ".editor-section",
                                                                        plugins: [
                                                                            'advlist autolink lists link image charmap print preview anchor',
                                                                            'searchreplace visualblocks code fullscreen',
                                                                            'insertdatetime media table paste code help wordcount'
                                                                        ],
                                                                        toolbar: 'undo redo | formatselect | ' +
                                                                            'bold italic backcolor | alignleft aligncenter ' +
                                                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                            'removeformat | help',
                                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md="4">
                                                            <label htmlFor="">Days per Week</label>
                                                            <FormGroup>
                                                                {/* <Select
                                                                    className="react-select warning"
                                                                    classNamePrefix="react-select"
                                                                    allowCreateWhileLoading={true}
                                                                    
                                                                    closeMenuOnSelect={false}
                                                                    placeholder=""
                                                                    name="multipleSelect"
                                                                    value={multipleSelect}
                                                                    options={selectOptions}
                                                                    onChange={(value) => setMultipleSelect(value)}
                                                                /> */}
                                                                <Creatable
                                                                    // onChange={handleChange}
                                                                    options={options}
                                                                    // onCreateOption={handleCreate}
                                                                    isClearable
                                                                    isCreatable
                                                                    isDisabled={isLoading}
                                                                    isLoading={isLoading}
                                                                // value={value}




                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </TabPanel>

                                            ))

                                        )

                                    }

                                </Tabs>
                            </CardBody>
                            <CardFooter className="text-right d-flex justify-content-between align-items-center">

                                <Button size="sm" color="info" onClick={duplicate}><i className="fas fa-plus-circle"></i></Button>

                                <Link to="/admin/add-job" className="btn btn-info">Next</Link>
                            </CardFooter>
                        </Card>
                    </Col>

                </Row>
            </div>
        </>
    );
}

export default AddNewArea;
