import { addNewArea } from 'actions/profileActions'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from 'reactstrap'

const AddAreaModal = ({
    open,
    toggleModal,
    addNewArea,
}) => {

    const [name, setName] = useState('')

    const handleChangeName = (e) => {
        setName(e.target.value)
    }

    const handleAddArea = (e) => {
        e.preventDefault()
        const data = {
            area: name,
        }

        addNewArea(data, toggleModal)
        setName('')
    }

    return (
        <>
            <Modal
                isOpen={open}
                toggle={toggleModal}
                className="text-center"
                size="md"
            >
                <ModalHeader
                    className="justify-content-start uppercase title text-left"
                    toggle={toggleModal}
                    tag="h6"
                >
                    Add Area
                </ModalHeader>
                <ModalBody>
                    <Form encType="multipart/form-data">
                        <Row>
                            <Col md="12" className="mb-2">
                                <FormGroup className="text-left">
                                    <Label
                                        className="text-left font-weight-bold">
                                        Area Name
                                    </Label>
                                    <Input
                                        type="text"
                                        placeholder="Area Name"
                                        value={name}
                                        name="fname"
                                        onChange={handleChangeName}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Button
                                color="info"
                                type="submit"
                                onClick={handleAddArea}
                            >
                                Add Area
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    allAreas: state.profileReducer.allAreas,
    userProfile: state.profileReducer.userProfile.user,
    addedSite: state.profileReducer.newSite.message,
    isLoading: state.profileReducer.isLoading
})

export default connect(mapStateToProps, { addNewArea })(AddAreaModal)