
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import {
    getAllStocks,
    addNewStock,
    updateStock,
    deleteStock,
    getAllStockCategories,
    addStockCategory,
    removeSweetAlert
} from "actions/profileActions";
import SweetAlert from "react-bootstrap-sweetalert";

// react component used to create sweet alerts
// reactstrap components
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { deleteStockCategory } from "actions/profileActions";
import { updateStockCategory } from "actions/profileActions";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const AllStocks = ({ getAllStocks, allStocks, addNewStock, updateStock, deleteStock, getAllStockCategories, allStockCategories, addStockCategory, removeSweetAlert, isLoading, deletedStock, updatedStock, newStock, sweetAlert, deleteStockCategory, updateStockCategory }) => {

    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {deletedStock ? deletedStock : updatedStock ? updatedStock : newStock ? newStock : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()

    };






    const history = useHistory();
    const [modalClassic, setModalClassic] = React.useState(false);
    const [modalViewStockCategory, setModalViewStockCategory] = React.useState(false);
    const [isUpdateStockCategory, setIsUpdateStockCategory] = React.useState(false);
    const [currentViewStockCategory, setCurrentViewStockCategory] = React.useState(null);
    const [updatedCategoryId, setUpdatedCategoryId] = React.useState(null);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };
    const toggleModalViewStockCategory = () => {
        setModalViewStockCategory(!modalViewStockCategory);
    };
    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic2);
    };
    const [modalClassic3, setModalClassic3] = React.useState(false);
    const toggleModalClassic3 = () => {
        setModalClassic3(!modalClassic3);
    };




    const [stocks, setStocks] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getAllStocks();
        getAllStockCategories();


    }, [])

    useEffect(() => {
        if (allStocks.length !== 0) {
            setStocks(allStocks.Stocks.reverse())
            setCategories(allStockCategories)


        } else {
            return null
        }
    })

    const [userProfile, setUserProfile] = useState([])
    const setModal = (row) => {
        toggleModalClassic()
        setUserProfile(row)


    }

    const setUpdateProfile = (row) => {
        toggleModalClassic2()
        setUserProfile(row)


    }



    var [formData, setFormData] = useState({
        id: '',
        title: '',
        quantity: '',
        company_id: '1',
        category_id: '',
        status: '1',
        name: ''



    })

    var { id, title, company_id, quantity, category_id, status, name } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const updateStaffSubmit = e => {
        e.preventDefault();

        if (id === '') {
            id = userProfile.id
        }
        if (title === '') {
            title = userProfile.title
        }
        if (company_id === '') {
            company_id = userProfile.company_id
        }

        if (quantity === '') {
            quantity = userProfile.quantity
        }

        if (status === '') {
            status = userProfile.status
        }

        var formData = new FormData();
        formData.append("stock_id", userProfile.id);
        formData.append("title", title);
        formData.append("company_id", company_id);
        formData.append("status", status);

        formData.append("quantity", quantity);
        updateStock(formData, history)

        setFormData({
            id: '',
            title: '',
            quantity: '',
            company_id: '1',
            category_id: '',
            status: '1'

        })

    }


    const addCategorySubmit = e => {
        e.preventDefault();
        var formData = new FormData();
        formData.append("name", name);
        formData.append("company_id", company_id);
        isUpdateStockCategory && formData.append("category_id", updatedCategoryId);

        isUpdateStockCategory ? updateStockCategory(formData, history) : addStockCategory(formData, history)

        setFormData({
            name: ''

        })

    }

    //  Add Stock Modal
    const setAddStockModal = () => {
        toggleModalClassic1()
    }
    const addStockSubmit = (e) => {
        e.preventDefault()
        var formData = new FormData();
        formData.append("category_id", category_id)
        formData.append("company_id", company_id)
        formData.append("quantity", quantity)
        formData.append("title", title)
        formData.append("status", status)

        addNewStock(formData, history)

        setFormData({
            id: '',
            title: '',
            quantity: '',
            company_id: '1',
            category_id: '',
            status: '1'

        })

    }

    function iconFormatter(cell, row) {
        return (
            <span>
                {cell === 0
                    ? 'Out of stock'
                    : cell === 1
                        ? 'On order'
                        : 'Full'}
            </span>
        );
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">

                {alert}

                <Row>
                    <div className="col">
                        <Card>

                            <Container>

                                <ToolkitProvider
                                    data={categories ? categories : []}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        // {
                                        //     dataField: "title",
                                        //     text: "Employee",
                                        //     sort: true,
                                        // },
                                        // {
                                        //     dataField: "title",
                                        //     text: "Site",
                                        //     sort: true,
                                        // },
                                        {
                                            dataField: "name",
                                            text: "Category Name",
                                            sort: true,
                                        },

                                        // {
                                        //     dataField: "title",
                                        //     text: "Stock",
                                        //     sort: true,
                                        // },

                                        // {
                                        //     dataField: "company",
                                        //     text: "Staff",
                                        //     sort: true,
                                        // },
                                        // {
                                        //     dataField: "quantity",
                                        //     text: "Quantity",
                                        //     sort: true,
                                        // },
                                        // {
                                        //     dataField: "status",
                                        //     text: "Status",
                                        //     sort: true,
                                        //     formatter: iconFormatter

                                        // },


                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="d-flex justify-content-centers">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => { toggleModalViewStockCategory(); setCurrentViewStockCategory(row) }} > <i className="fas fa-eye"></i></span>
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => { setIsUpdateStockCategory(true); toggleModalClassic3(); setUpdatedCategoryId(row.id); setFormData({ ...formData, name: row.name }) }} > <i className="fas fa-edit"></i></span>

                                                        {
                                                            isLoading ? (
                                                                <div class="spinner-border text-primary" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            ) : (

                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteStockCategory(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        }
                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <div>
                                                    <Link className="btn btn-info btn-sm" onClick={() => { toggleModalClassic3(); setIsUpdateStockCategory(false); setUpdatedCategoryId(null) }}>Add New Category</Link>
                                                    {/* <Link className="btn btn-info btn-sm" onClick={setAddStockModal}>Add New Stock</Link> */}
                                                </div>


                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>


                            <Container>

                                <ToolkitProvider
                                    data={stocks}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "category.name",
                                            text: "Category",
                                            sort: true,
                                        },

                                        {
                                            dataField: "title",
                                            text: "Stock",
                                            sort: true,
                                        },
                                        {
                                            dataField: "quantity",
                                            text: "Quantity",
                                            sort: true,
                                        },
                                        {
                                            dataField: "status",
                                            text: "Status",
                                            sort: true,
                                            formatter: iconFormatter
                                        },


                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="d-flex justify-content-center">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)} > <i className="fas fa-eye"></i></span>
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateProfile(row)} > <i className="fas fa-edit"></i></span>

                                                        {
                                                            isLoading ? (
                                                                <div class="spinner-border text-primary" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            ) : (

                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteStock(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        }
                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <div>
                                                    {/* <Link className="btn btn-info btn-sm" onClick={toggleModalClassic3}>Add New Category</Link> */}
                                                    <Link className="btn btn-info btn-sm" onClick={setAddStockModal}>Add New Stock</Link>
                                                </div>


                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>
                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        {userProfile.title}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Title</td>
                                    <td className="border-0">{userProfile.title}</td>
                                    <td className="border-0 font-weight-bold">Quantity</td>
                                    <td className="border-0">{userProfile.quantity}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Status</td>
                                    <td className="border-0">{userProfile.status}</td>
                                    <td className="border-0 font-weight-bold">Created At</td>
                                    <td className="border-0">{userProfile.created_at}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Category</td>
                                    <td className="border-0">{userProfile.category_id}</td>

                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>

                {/* View Stock Category Modal */}
                <Modal
                    isOpen={modalViewStockCategory}
                    toggle={toggleModalViewStockCategory}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalViewStockCategory}
                        tag="h6"
                    >
                        Category Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Category Name</td>
                                    <td className="border-0">{currentViewStockCategory?.name}</td>

                                </tr>
                                {/* <tr className="border">
                                    <td className="border-0 font-weight-bold">Title</td>
                                    <td className="border-0">{userProfile.title}</td>
                                    <td className="border-0 font-weight-bold">Quantity</td>
                                    <td className="border-0">{userProfile.quantity}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Status</td>
                                    <td className="border-0">{userProfile.status}</td>
                                    <td className="border-0 font-weight-bold">Created At</td>
                                    <td className="border-0">{userProfile.created_at}</td>
                                </tr> */}
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>


                {/* Update Stock Modal */}

                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        {userProfile.title}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>


                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Title </Label>
                                        <Input type="text" placeholder={userProfile.title} value={title} name="title" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Quantity </Label>
                                        <Input type="number" placeholder={userProfile.quantity} value={quantity} name="quantity" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Status </Label>
                                        <select name="status" value={status} onChange={onChange} id="" className="form-control">
                                            <option value="1">Active</option>
                                            <option value="0">InActive</option>
                                        </select>
                                    </FormGroup>
                                </Col>





                            </Row>
                            <div className="text-center">
                                {
                                    isLoading ? (
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    ) : (

                                        <Button color="info" type="submit"  >
                                            Update
                                        </Button>
                                    )
                                }

                            </div>

                        </Form>

                    </ModalBody>


                </Modal>


                {/* Add Stock Modal */}

                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.title}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => addStockSubmit(e)} encType="multipart/form-data">
                            <Row>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Category </Label>
                                        <select name="category_id" className="form-control" value={category_id} id="" onChange={(e) => onChange(e)}>
                                            <option value="">Select</option>
                                            {
                                                modalClassic1 ? (
                                                    allStockCategories.map(category => (
                                                        <option value={category.id}>{category.name}</option>
                                                        // console.log(category.name)

                                                    ))
                                                ) : (
                                                    <option value="">No Categories Found</option>
                                                )
                                            }
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Product </Label>
                                        <Input type="text" value={title} name="title" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Quantity </Label>
                                        <Input type="number" value={quantity} name="quantity" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Status </Label>
                                        <select name="status" value={status} onChange={onChange} id="" className="form-control">
                                            <option value="0">Out of Stock</option>
                                            <option value="1">On Order</option>
                                            <option value="2">Full</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-center">
                                {
                                    isLoading ? (
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    ) : (

                                        <Button color="info" type="submit"  >
                                            Add
                                        </Button>
                                    )
                                }

                            </div>

                        </Form>

                    </ModalBody>


                </Modal>


                {/* Add Category Modal */}

                <Modal
                    isOpen={modalClassic3}
                    toggle={toggleModalClassic3}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic3}
                        tag="h6"
                    >
                        {isUpdateStockCategory ? "Update Stock Category" : "Add Stock Category"}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => addCategorySubmit(e)} encType="multipart/form-data">
                            <Row>
                                <Col md="12" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Category Name </Label>
                                        <Input type="text" value={name} name="name" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit"  >
                                    {isUpdateStockCategory ? "Update" : "Add"}
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>

        </>
    );
}

const mapStateToProps = state => ({
    allStocks: state.profileReducer.allStocks,
    allStockCategories: state.profileReducer.allStockCategories.categories,
    isLoading: state.profileReducer.isLoading,
    sweetAlert: state.profileReducer.sweetAlert,
    newStock: state.profileReducer.newStock.message,
    deletedStock: state.profileReducer.deletedStock.message,
    updatedStock: state.profileReducer.updatedStock.message,
})

export default connect(mapStateToProps, { getAllStocks, updateStock, addNewStock, deleteStock, getAllStockCategories, addStockCategory, removeSweetAlert, deleteStockCategory, updateStockCategory })(AllStocks);























