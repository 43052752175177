
import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux'
import { getAllClients, getUserProfile } from "actions/profileActions";
import Select from "react-select";
import Multiselect from 'multiselect-react-dropdown';
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import InputMask from 'react-input-mask';
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { addNewSite, removeSweetAlert } from "actions/profileActions";
import AddAreaModal from "components/Modals/AddAreaModal";
import { getAllAreas } from "actions/profileActions";
import AddBuildingModal from "components/Modals/AddBuildingModal";
import { getAllBuildings } from "actions/profileActions";
import { StandaloneSearchBox, useJsApiLoader, LoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


var positions;

const RegularMap = ({ mapLocation }) => {
    console.log("🚀 ~ RegularMap ~ mapLocation:", mapLocation)
    const mapRef = React.useRef(null);
    // const [location, setLocation] = useState({ lat: -33.8688, lng: 151.2073 });

    React.useEffect(() => {
        let google = window.google;
        let map = mapRef.current;

        const myLatlng = new google.maps.LatLng(mapLocation.lat, mapLocation.lng);
        const mapOptions = {
            zoom: 13,
            center: myLatlng,
            scrollwheel: false,
        };

        map = new google.maps.Map(map, mapOptions);

        const marker = new google.maps.Marker({
            position: myLatlng,
            map: map,
            animation: google.maps.Animation.DROP,


            title: "Set Location",
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: "red",
                fillOpacity: 1.0,
                strokeColor: "white",
                strokeWeight: 5,
                scale: 10,
            },
        });

        let infoWindow = new google.maps.InfoWindow({
            content: `Location: ${mapLocation.lat}, ${mapLocation.lng}`,
            position: myLatlng,
        });
        infoWindow.open(map, marker);

        map.addListener("click", (mapsMouseEvent) => {
            const newLocation = mapsMouseEvent.latLng;
            // setLocation(newLocation);
            infoWindow.close();
            marker.setPosition(newLocation);
            infoWindow.setContent(`Location: ${newLocation.lat()}, ${newLocation.lng()}`);
            infoWindow.open(map, marker);
        });
    }, [mapLocation]);

    const handleLocationChange = (newLocation) => {
        // setLocation(newLocation);
    };

    return (
        <>
            <div style={{ height: `280px` }} ref={mapRef} />
        </>
    );
};

const CreateSite = ({ getAllClients, allClients, allAreas, allBuildings, userProfile, getUserProfile, addNewSite, getAllAreas, getAllBuildings, removeSweetAlert, sweetAlert, addedSite, isLoading }) => {

    const [singleFileName, setSingleFileName] = React.useState("");
    const [singleFile, setSingleFile] = React.useState(null);
    const singleFileRef = React.useRef();
    const [mapLocations, setMapLocations] = useState(positions)
    const [open, setOpen] = useState(false)
    const [buildingOpen, setBuildingOpen] = useState(false)
    const [accesses, setAccesses] = useState([])
    const [securityAccess, setSecurityAccess] = useState([])
    const [formData, setFormData] = useState({
        client_id: '',
        company_id: '1',
        site_name: '',
        emergency_contact_no: '',
        emergency_phone_no: '',
        out_of_hours: '',
        alarm_call: '',
        lat: '',
        lan: '',
        location: '',
        town: '',
        post_code: "",
        address: "",
        email: "",
        client_contact: "",
        file: [],
        buildings: accesses,

    })

    // TODO: GOOGLE AUTOCOMPLETE API 
    const inputRef = useRef(null);
    const [isLoadingAPI, setIsLoadingAPI] = useState(true);
    const [suggestions, setSuggestions] = useState([]);
    const [mapLocation, setMapLocation] = useState({ lat: -33.8688, lng: 151.2073 });

    useEffect(() => {
        const initAutocomplete = () => {
            const google = window.google;
            const options = {
                fields: ["address_components", "geometry", "icon", "name"],
            };
            const autocomplete = new google.maps.places.Autocomplete(inputRef.current, options);

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                setMapLocation({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                })
                // setFormData({ 
                //     ...formData, 
                //     location: place.name,
                //     lat: place.geometry.location.lat(),
                //     lan: place.geometry.location.lng(),
                //  })
                // console.log("🚀 ~ autocomplete.addListener ~ place:", place.geometry.location.lat())
                // console.log("🚀 ~ autocomplete.addListener ~ place:", place.geometry.location.lng())
                // console.log(place);
            });

            autocomplete.addListener('place_changed', () => {
                setSuggestions([]);
            });

            autocomplete.addListener('input', () => {
                const input = autocomplete.getPlace();
                const predictions = autocomplete.getPlacePredictions();
                setSuggestions(predictions);
            });
        };

        if (window.google && window.google.maps && window.google.maps.places) {
            initAutocomplete();
            setIsLoadingAPI(false);
        } else {
            console.error('Google Maps API not loaded yet');
        }
    }, []);

    // TODO: END

    useEffect(() => {
        setMapLocations(positions)

    }, [positions])

    const handleSingleFileInput = (e) => {
        singleFileRef.current.click(e);
    };
    const addSingleFile = (e, type) => {
        let fileNames = "";
        let files = e.target.files;
        for (let i = 0; i < e.target.files.length; i++) {
            fileNames = fileNames + e.target.files[i].name;
        }
        setSingleFile(files);
        setSingleFileName(fileNames);
    };

    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {addedSite}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };

    const [buildingareas, setbuildingareas] = useState([])
    const [areas, setAreas] = useState([])
    const addNewArea = () => {
        // let newArr = [...buildingareas]
        // const newObj = {
        //     name: ''
        // }
        // newArr.push(newObj)
        // setbuildingareas(newArr)
        setOpen(true)
    }
    const changeAreaName = (index) => e => {
        let newArr = [...buildingareas]
        newArr[index]['name'] = e.target.value;
        setbuildingareas(newArr)
    }

    const addNewAccess = () => {
        // let newArr = [...accesses]
        // const newObj = {
        //     name: ''
        // }
        // newArr.push(newObj)
        // setAccesses(newArr)
        setBuildingOpen(true)
    }
    const changeAccessName = (index) => e => {
        let newArr = [...accesses]
        newArr[index]['name'] = e.target.value;
        setAccesses(newArr)
    }

    //  ----------------------------------

    const history = useHistory();
    const [singleSelect, setSingleSelect] = React.useState(null);



    const { client_id, company_id, site_name, emergency_contact_no, out_of_hours, emergency_phone_no, alarm_call, lat, lan, location, town, post_code, address, email, client_contact, file, buildings } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })
    const onChange1 = e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    const [uploadedFile, setUploadedFile] = useState([])

    const handleImageChange = (e) => {
        const files = e.target.files;

        const newFiles = Array.from(files).map(file => ({
            file,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        }));

        setUploadedFile(prevFiles => [...prevFiles, ...newFiles]);
        setFormData({ ...formData, file: [...formData.file, ...files] });
    };

    useEffect(() => {
        getAllClients()
        getAllAreas()
        getAllBuildings()
    }, [])

    useEffect(() => {
        if (client_id === '' || client_id === null || client_id === undefined) {
            return null
        } else {
            var formdata = new FormData();
            formdata.append("id", client_id);
            getUserProfile(formdata)
        }
    }, [client_id])


    const onSubmit = e => {
        e.preventDefault();

        const areasIds = areas?.map(data => data?.value)
        const buildingsIds = securityAccess?.map(data => data?.value)

        var raw = {
            "company_id": "1",
            "client_id": client_id,
            "site_name": site_name,
            "emergency_contact_no": emergency_contact_no,
            "emergency_phone_no": emergency_phone_no,
            "out_of_hours": out_of_hours,
            "alarm_call": alarm_call,
            "lat": mapLocation.lat,
            "lan": mapLocation.lng,
            "location": inputRef.current.value,
            "town": userProfile.town,
            "post_code": post_code,
            "address": userProfile.address,
            "email": userProfile.email,
            "client_contact": userProfile.mobile_no,
            "file": file,
            "areas": areasIds,
            "buildings": buildingsIds
        }
        addNewSite(raw, history)
    }

    const toggleModal = () => {
        setOpen(!open)
    }

    const buildingToggleModal = () => {
        setBuildingOpen(!buildingOpen)
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}
                <Row>
                    <Col md="12">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h4">Create Site</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={e => onSubmit(e)} encType="multipart/form-data">
                                    <Row>
                                        <Col md="6">
                                            <label>Company Name</label>
                                            <FormGroup>
                                                <select name="client_id" required className="form-control" type="select" value={client_id} onChange={(e) => onChange(e)}>
                                                    <option value="" >Select</option>

                                                    {
                                                        allClients.clients.map(client => (
                                                            <option value={client.id}>{client?.company_name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Contact</label>
                                            <FormGroup>
                                                <Input type="text" value={userProfile ? userProfile.phone_no : ''} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <label>Address</label>
                                            <FormGroup>
                                                <Input type="text" value={userProfile ? userProfile.address : ''} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Town</label>
                                            <FormGroup>
                                                <Input type="text" value={userProfile ? userProfile.town : ''} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Post Code</label>
                                            <FormGroup>
                                                <Input type="text" value={userProfile ? userProfile.post_code : ''} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Telephone</label>
                                            <FormGroup>
                                                <Input type="text" value={userProfile ? userProfile.mobile_no : ''} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <label>Email</label>
                                            <FormGroup>
                                                <Input type="email" value={userProfile ? userProfile.email : ''} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Site Name</label>
                                            <FormGroup>
                                                <Input type="text" onChange={onChange} name="site_name" value={site_name} />
                                            </FormGroup>
                                        </Col>
                                        {/* TODO: OLD LOCATION  */}
                                        {/* <Col md="6">
                                            <label>Location</label>
                                            <FormGroup>
                                                <Input type="text" onChange={onChange} name="location" value={location} />
                                            </FormGroup>
                                        </Col> */}
                                        {/* TODO: OLD LOCATION END  */}
                                        <Col md="6">
                                            <label>Location</label>
                                            <FormGroup>
                                                <div>
                                                    {isLoadingAPI && <p>Loading Google Places API...</p>}
                                                    <input type="text" id="my-place-input" ref={inputRef} placeholder="Enter a location"
                                                        style={{
                                                            width: '100%',
                                                            padding: '8px',
                                                            borderRadius: '50px',
                                                            outline: 'none',
                                                        }}
                                                    />
                                                    {/* <Input type="text"  id="my-place-input" ref={inputRef} onChange={onChange} name="location" value={location} /> */}
                                                    <ul>
                                                        {suggestions.map((suggestion) => (
                                                            <li key={suggestion.id}>{suggestion.description}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Post Code</label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    value={post_code}
                                                    name="post_code"
                                                    onChange={onChange}
                                                    mask="aa99 9aa"
                                                    tag={InputMask}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Emergency Contact Name</label>
                                            <FormGroup>
                                                <Input type="text" value={emergency_contact_no} name="emergency_contact_no" onChange={onChange} />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <label>Emergency Phone #</label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    value={emergency_phone_no}
                                                    name="emergency_phone_no"
                                                    onChange={onChange}
                                                    mask="0 99 9999 9999"
                                                    tag={InputMask}
                                                    onCopy={(e) => {
                                                        e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Out of Hours Telephone</label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    value={out_of_hours}
                                                    name="out_of_hours"
                                                    onChange={onChange}
                                                    mask="0 99 9999 9999"
                                                    tag={InputMask}
                                                    onCopy={(e) => {
                                                        e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <label>Alarm Call Out Number</label>
                                            <FormGroup>
                                                <Input
                                                    type="text"
                                                    value={alarm_call}
                                                    name="alarm_call"
                                                    onChange={onChange}
                                                    mask="0 99 9999 9999"
                                                    tag={InputMask}
                                                    onCopy={(e) => {
                                                        e.clipboardData.setData('text/plain', e.target.value.substring(1));
                                                        e.preventDefault();
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup className="text-left">
                                                <label>File Upload</label>
                                                <FormGroup className="">
                                                    <input
                                                        type="file"
                                                        style={{
                                                            opacity: '1',
                                                            position: 'relative'
                                                        }}
                                                        name="file"
                                                        multiple
                                                        accept="image/jpeg, image/png, application/pdf"
                                                        onChange={e => handleImageChange(e)} className="form-control"
                                                    />
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col md="12">
                                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                {uploadedFile.map((fileItem, index) => (
                                                    <div key={index} style={{ margin: '10px' }}>
                                                        {fileItem.preview ? (
                                                            <img
                                                                src={fileItem.preview}
                                                                alt={`Uploaded Image ${index}`}
                                                                style={{ width: '100px', height: '100px' }}
                                                            />
                                                        ) : (
                                                            <div>
                                                                <span style={{ fontWeight: 'bold' }}>{fileItem.file.name} selected</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                        <Col md="6" className="mb-5">
                                            <label>Areas  <i className="fas fa-plus-circle text-info" onClick={addNewArea}></i></label>
                                            {/* {
                                                buildingareas.map((area, index) => (
                                                    <FormGroup key={index}>
                                                        <Input type="text" onChange={changeAreaName(index)} />
                                                    </FormGroup>
                                                ))
                                            } */}
                                            <Select
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={areas}
                                                onChange={(e) => {
                                                    if (areas?.length < 10) {
                                                        setAreas(e)
                                                    }
                                                }}
                                                options={allAreas?.areas?.map(data => ({
                                                    value: data?.id,
                                                    label: data?.name,
                                                }))}
                                            />

                                        </Col>

                                        <Col md="6" className="mb-5">
                                            <label>Building Access & Security <i className="fas fa-plus-circle text-info" onClick={addNewAccess}></i></label>
                                            {/* {
                                                accesses.map((access, index) => (
                                                    <FormGroup key={index}>
                                                        <Input type="text" onChange={changeAccessName(index)} />
                                                    </FormGroup>

                                                ))
                                            } */}

                                            <Select
                                                isMulti
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                value={securityAccess}
                                                onChange={(e) => {
                                                    if (securityAccess?.length < 5) {
                                                        setSecurityAccess(e)
                                                    }
                                                }}
                                                options={allBuildings?.buildings?.map(data => ({
                                                    value: data?.id,
                                                    label: data?.name,
                                                }))}
                                            />
                                        </Col>

                                        <Col md="12">
                                            <RegularMap mapLocation={mapLocation} />
                                        </Col>
                                    </Row>


                                    <div className="text-center">
                                        {
                                            isLoading ? (
                                                <div class="spinner-border text-info" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            ) : (
                                                <Button className="btn-fill" color="info" type="submit">
                                                    Submit
                                                </Button>
                                            )
                                        }

                                    </div>
                                </Form>
                            </CardBody>


                        </Card>
                    </Col>

                </Row>
            </div>

            <AddAreaModal
                open={open}
                toggleModal={toggleModal}
            />

            <AddBuildingModal
                open={buildingOpen}
                toggleModal={buildingToggleModal}
            />
        </>
    );
}

const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    allAreas: state.profileReducer.allAreas,
    allBuildings: state.profileReducer.allBuildings,
    userProfile: state.profileReducer.userProfile.user,
    addedSite: state.profileReducer.newSite.message,
    isLoading: state.profileReducer.isLoading
})

export default connect(mapStateToProps, { getAllClients, getUserProfile, addNewSite, getAllAreas, getAllBuildings, removeSweetAlert })(CreateSite);
