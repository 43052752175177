
import Dashboard from "views/Dashboard/Dashboard.js";
import AddClient from "views/Forms/AddClient";
import AddManager from "views/Forms/AddManager";
import AddNewArea from "views/Forms/AddNewArea";
import AddNewJob from "views/Forms/AddNewJob";
import AddNewTask from "views/Forms/AddNewTask";
import CreateSite from "views/Forms/CreateSite";
import Notes from "views/Forms/Notes";
import UpdateSite from "views/Forms/UpdateSite";
import EditProfile from "views/Pages/EditProfile.js";
import LoginPage from "views/Pages/LoginPage.js";
import MyProfile from "views/Pages/MyProfile.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import Alert from "views/Tables/Alert";
import AllStocks from "views/Tables/AllStocks";
import LeaveRequests from "views/Tables/LeaveRequests";
import ManageClients from "views/Tables/ManageClients";
import ManageJobs from "views/Tables/ManageJobs";
import ManageSite from "views/Tables/ManageSite";
import StaffDatabase from "views/Tables/StaffDatabase";
import StockRequests from "views/Tables/StockRequests";
import TimeSheets from "views/Tables/TimeSheets";
import VideoTutorials from "views/Tables/VideoTutorials";

let routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "now-ui-icons design_app",
    component: Dashboard,
    layout: "/admin",
    private: true
  },
  {
    path: "/manage-jobs",
    name: "Manage Jobs",
    icon: "fas fa-thumbtack",
    component: ManageJobs,
    layout: "/admin",
  },
  {
    path: "/staff-database",
    name: "Team",
    icon: "fas fa-user-friends",
    component: StaffDatabase,
    layout: "/admin",
    private: true
  },
  {
    path: "/leave-requests",
    name: "Leave Records",
    icon: "fas fa-user-friends",
    component: LeaveRequests,
    layout: "/admin",
    private: true
  },
  {
    path: "/time-sheets",
    name: "Timesheets",
    icon: "fas fa-clock",
    component: TimeSheets,
    layout: "/admin",
    private: true
  },
  {
    path: "/manage-clients",
    name: "Client Database",
    icon: "fas fa-users",
    component: ManageClients,
    layout: "/admin",
    private: true
  },
  {
    path: "/add-new-job",
    name: "Task Specifications",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddNewTask,
    layout: "/admin",
  },
  {
    path: "/add-new-area",
    name: "Task Specifications",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddNewArea,
    layout: "/admin",
    private: true,
  },
  {
    path: "/manage-sites",
    name: "Manage Sites",
    icon: "fas fa-building",
    component: ManageSite,
    layout: "/admin",
    private: true,
  },

  {
    collapse: true,
    path: "/pages",
    name: "Supplies",
    state: "openStocks",
    icon: "fas fa-cog",
    views: [
      {
        path: "/all-stocks",
        name: "All Supplies",
        mini: "S",
        component: AllStocks,
        layout: "/admin",
        private: true
      },
      {
        path: "/stock-requests",
        name: "Supplies Requests",
        short: "SR",
        mini: "SR",
        component: StockRequests,
        layout: "/admin",
      },


    ],
  },
  {
    path: "/VideoTutorials",
    name: "Knowledge Base",
    icon: "fas fa-book-open",
    component: VideoTutorials,
    layout: "/admin",
    private: true,
  },
  {
    path: "/create-site",
    name: "Create Site",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: CreateSite,
    layout: "/admin",
    private: true
  },
  {
    path: "/update-site",
    name: "Create Site",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: UpdateSite,
    layout: "/admin",
    private: true
  },
  {
    path: "/add-client",
    name: "Add Client",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddClient,
    layout: "/admin",
    private: true,
  },
  {
    path: "/add-manager",
    name: "Add Manager",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddManager,
    layout: "/admin",
    private: true,
  },
  {
    path: "/add-job",
    name: "Add New Job",
    icon: "now-ui-icons objects_diamond",
    invisible: true,
    component: AddNewJob,
    layout: "/admin",
    private: true
  },
  {
    path: "/edit-profile",
    name: "Profile",
    mini: "UP",
    component: EditProfile,
    invisible: true,
    layout: "/admin",
    private: true
  },
  {
    path: "/my-profile",
    name: "Profile",
    mini: "UP",
    component: MyProfile,
    invisible: true,
    layout: "/admin",
    private: true
  },
  {
    path: "/notes",
    name: "Notes",
    icon: "now-ui-icons objects_diamond",
    component: Notes,
    layout: "/admin",
    invisible: true,
  },
  {
    path: "/login-page",
    name: "Login Page",
    short: "Login",
    mini: "LP",
    component: LoginPage,
    invisible: true,
    layout: "/auth",
  },
  {
    path: "/register-page",
    name: "Register Page",
    short: "Register",
    mini: "RP",
    component: RegisterPage,
    layout: "/auth",
    invisible: true
  },
  {
    path: "/alert",
    name: "Alert",
    short: "Alert",
    mini: "RP",
    component: Alert,
    layout: "/admin",
    invisible: true
  },
  {
    collapse: true,
    path: "/pages",
    name: "Settings",
    state: "openPages",
    icon: "fas fa-cog",
    views: [
      {
        path: "/edit-profile",
        name: "Edit Profile",
        mini: "EP",
        component: EditProfile,
        layout: "/admin",
        private: true
      },
      {
        path: "/login-page",
        name: "Logout",
        short: "Login",
        mini: "LP",
        component: LoginPage,
        layout: "/auth",
        clickevent: true
      },


    ],
  },
];

export default routes;
