
import {
    addNewClient,
    deleteClient,
    getAllClients,
    removeSweetAlert,
    updateClient
} from "actions/profileActions";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import { postalCodeErrTxt } from "config/constants";
import { postalCodeRegex } from "config/constants";
import React, {
    useEffect, useState
} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search
} from "react-bootstrap-table2-toolkit";
import InputMask from 'react-input-mask';
import {
    connect
} from 'react-redux';
import {
    Link, useHistory
} from 'react-router-dom';
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Input, Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row
} from "reactstrap";

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const ManageClients = ({ getAllClients, allClients, addNewClient, updateClient, deleteClient, removeSweetAlert, clientAddedMsg, isLoading, sweetAlert, deletedMsg }) => {

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {clientAddedMsg ? clientAddedMsg : deletedMsg ? deletedMsg : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };


    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic1);
    };


    const [clients, setClients] = React.useState([]);

    const [userProfile, setUserProfile] = useState([])

    var [formData, setFormData] = useState({
        company_name,
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: '',
        notes: '',
        division: '',
        service_team: '',
        invoice_date: '',
        visits: '',
        file: [],
        date_signed: '',
        status: '',
    })

    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)
    }


    const setUpdateProfile = (row) => {
        toggleModalClassic()
        setUserProfile(row)
        setFormData({
            company_name: row?.company_name == null ? "" : row?.company_name,
            fname: row?.fname == null ? "" : row?.fname,
            lname: row?.lname == null ? "" : row?.lname,
            email: row?.email == null ? "" : row?.email,
            address: row?.address == null ? "" : row?.address,
            post_code: row?.post_code == null ? "" : row?.post_code,
            town: row?.town == null ? "" : row?.town,
            phone_no: row?.phone_no == null ? "" : row?.phone_no,
            mobile_no: row?.mobile_no == null ? "" : row?.mobile_no,
            password: row?.password == null ? "" : row?.password,
            notes: row?.notes == null ? "" : row?.notes,
            division: row?.division == null ? "" : row?.division,
            service_team: row?.service_team == null ? "" : row?.service_team,
            invoice_date: row?.invoice_date == null ? "" : row?.invoice_date,
            visits: row?.visits == null ? "" : row?.visits,
            file: row?.attachments == null ? "" : row?.attachments,
            date_signed: row?.date_signed == null ? "" : row?.date_signed,
            status: row?.status == null ? "" : row?.status,
        })
    }

    useEffect(() => {
        getAllClients();

    }, [])



    useEffect(() => {
        if (allClients.length !== 0) {
            setClients(allClients.clients.reverse())


        } else {
            return null
        }
    })

    const [errors, setErrors] = useState({})

    var { company_name, fname, lname, address, post_code, mobile_no, phone_no, email, town, password, notes, division, service_team, invoice_date, visits, file, date_signed, status } = formData;
    const onChange = e => {

        if (e.target.name == "post_code") {

            const result
                = postalCodeRegex
                    .test(
                        e.target.value
                    )

            if (!result) {
                setFormData((prev) => ({
                    ...prev,
                    [e.target.name]:
                        null
                }))
                setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        postalCodeErrTxt
                }))
            } else {
                setFormData((prev) => ({
                    ...prev,
                    [e.target.name]:
                        e.target.value
                }))
                setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        null
                }))

            }
        } else
            setFormData((prev) => ({
                ...prev,
                [e.target.name]:
                    e.target.value
            }))
    }
    const onChange1 = e => setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    const [uploadedFile, setUploadedFile] = useState([])
    const [newFiles, setNewFiles] = useState([])
    // const handleImageChange = (e) => {
    //     const file = e.target.files[0];
    //     setFormData({ ...formData, [e.target.name]: e.target.files[0] })

    //     if (file) {
    //         const reader = new FileReader();

    //         reader.onloadend = () => {
    //             setUploadedFile(reader.result);
    //         };

    //         reader.readAsDataURL(file);
    //     }
    // };

    const handleImageChange = (e) => {
        const files = e.target.files;

        const newFiles = Array.from(files).map(file => ({
            file,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
        }));

        setUploadedFile(prevFiles => [...prevFiles, ...newFiles]);
        // setFormData({ ...formData, file: [...formData.file, ...files] });
        setNewFiles([...newFiles, ...files]);
    };




    const updateStaffSubmit = e => {
        e.preventDefault();

        // if (company_name === '') {
        //     company_name = userProfile.company_name
        // }
        // if (fname === '') {
        //     fname = userProfile.fname
        // }
        // if (lname === '') {
        //     lname = userProfile.lname
        // }
        // if (address === '') {
        //     address = userProfile.address
        // }
        // if (post_code === '') {
        //     post_code = userProfile.post_code
        // }
        // if (mobile_no === '') {
        //     mobile_no = userProfile.mobile_no
        // }
        // if (phone_no === '') {
        //     phone_no = userProfile.phone_no
        // }
        // if (email === '') {
        //     email = userProfile.email
        // }
        // if (town === '') {
        //     town = userProfile.town
        // }
        // if (notes === '') {
        //     notes = userProfile.notes
        // }
        // if (division === '') {
        //     division = userProfile.division
        // }
        // if (service_team === '') {
        //     service_team = userProfile.service_team
        // }
        // if (invoice_date === '') {
        //     invoice_date = userProfile.invoice_date
        // }
        // if (visits === '') {
        //     visits = userProfile.visits
        // }
        // if (file === '') {
        //     file = userProfile.file
        // }
        // if (date_signed === '') {
        //     date_signed = userProfile.date_signed
        // }
        // if (status === '') {
        //     status = userProfile.status
        // }
        const mergedFiles = [...userProfile?.attachments, ...newFiles]





        var formData = new FormData();
        formData.append("client_id", userProfile.id);
        formData.append("company_id", '1');
        formData.append("company_name", company_name);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        formData.append("password", password);
        formData.append("notes", notes);
        formData.append("division", division);
        formData.append("service_team", service_team);
        formData.append("invoice_date", invoice_date);
        formData.append("visits", visits);
        // formData.append("file", file);
        mergedFiles.forEach((data, index) => {
            formData.append(`file[]`, data)
        });
        formData.append("date_signed", date_signed);
        formData.append("status", status);
        updateClient(formData, history)

        setFormData({
            company_name: '',
            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: ''
        })
    }



    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">

                {alert}

                <Row>
                    <div className="col">
                        <Card>

                            <Container>


                                <ToolkitProvider
                                    data={clients}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "fname",
                                            text: "Name",
                                            sort: true,
                                        },
                                        {
                                            dataField: "post_code",
                                            text: "Post Code",
                                            sort: true,
                                        },
                                        // {
                                        //     dataField: "company",
                                        //     text: "Site",
                                        //     sort: true,
                                        // },
                                        {
                                            dataField: "email",
                                            text: "Email",
                                            sort: true,
                                        },
                                        {
                                            dataField: "phone_no",
                                            text: "Contact",
                                            sort: true,
                                        },


                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="d-flex justify-content-center">
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setModal(row)} > <i className="fas fa-eye"></i></span>
                                                        <span className="btn btn-info btn-sm m-1" onClick={() => setUpdateProfile(row)}> <i className="fas fa-edit"></i></span>
                                                        {
                                                            isLoading ? (
                                                                <div class="spinner-border text-info" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            ) : (
                                                                <span className="btn btn-danger btn-sm m-1" onClick={() => deleteClient(row.id, history)} > <i className="fas fa-times"></i></span>
                                                            )
                                                        }


                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-1 d-flex  align-items-center justify-content-between"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>
                                                <Link className="btn btn-info btn-sm" to="/admin/add-client">Add New Client</Link>

                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>


                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">First Name</td>
                                    <td className="border-0">{userProfile.fname}</td>
                                    <td className="border-0 font-weight-bold">Last Name</td>
                                    <td className="border-0">{userProfile.lname}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Email</td>
                                    <td className="border-0">{userProfile.email}</td>
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{userProfile.address}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Phone No</td>
                                    <td className="border-0">{userProfile.phone_no}</td>
                                    <td className="border-0 font-weight-bold">Post Code</td>
                                    <td className="border-0">{userProfile.post_code}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Mobile No</td>
                                    <td className="border-0">{userProfile.mobile_no}</td>
                                    <td className="border-0 font-weight-bold">Town</td>
                                    <td className="border-0">{userProfile.town}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Notes</td>
                                    <td className="border-0">{userProfile.notes}</td>
                                    <td className="border-0 font-weight-bold">Division</td>
                                    <td className="border-0">{userProfile.division}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Date Signed</td>
                                    <td className="border-0">{userProfile.date_signed}</td>
                                    <td className="border-0 font-weight-bold">Status</td>
                                    <td className="border-0">{userProfile.status === 1 ? 'Active' : 'InActive'}</td>
                                </tr>
                                <tr className="border">
                                <td className="border-0 font-weight-bold">Company Name</td>
                                    <td className="border-0">{userProfile.company_name}</td>
                                    <td className="border-0 font-weight-bold">File</td>
                                    <td className="border-0">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {
                                                userProfile?.attachments?.map(data => (
                                                    <div
                                                        key={data?.id}
                                                        style={{
                                                            margin: '4px'
                                                        }}
                                                    >
                                                        {
                                                            data?.path?.includes('.pdf')
                                                                ?
                                                                <a target="_blank" href={`https://api.eagleiq.online/${data?.path}`}>See Attachment</a>
                                                                :
                                                                <img
                                                                    src={`https://api.eagleiq.online/${data?.path}`}
                                                                    alt="Profile"
                                                                    style={{ width: '80px', height: '80px' }}
                                                                />
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={modalClassic}
                    toggle={toggleModalClassic}
                    className="text-center"
                    size="xl"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold">Client Name (The Company Name)</Label>
                                        <Input type="text" placeholder={userProfile.company_name} value={company_name} name="company_name" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> First Name </Label>
                                        <Input type="text" placeholder={userProfile.fname} value={fname} name="fname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Last Name </Label>
                                        <Input type="text" placeholder={userProfile.lname} value={lname} name="lname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Email </Label>
                                        <Input type="email" placeholder={userProfile.email} value={email} name="email" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Address </Label>
                                        <Input type="text" placeholder={userProfile.address} value={address} name="address" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Post Code </Label>
                                        <Input
                                            placeholder={userProfile.post_code}
                                            value={post_code}
                                            name="post_code"
                                            onChange={onChange}
                                        />
                                        <p
                                            style={{
                                                fontSize: '10px',
                                                color: '#D63343'
                                            }}
                                        >
                                            {errors?.post_code}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Town </Label>
                                        <Input type="text" placeholder={userProfile.town} value={town} name="town" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Phone No </Label>
                                        <Input
                                            placeholder={userProfile.phone_no}
                                            value={phone_no}
                                            name="phone_no"
                                            onChange={onChange}
                                            type="text"
                                            mask="+44 99 9999 9999"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold">
                                            Contact No
                                        </Label>
                                        <Input
                                            placeholder={userProfile.mobile_no}
                                            value={mobile_no}
                                            name="mobile_no"
                                            onChange={onChange}
                                            type="text"
                                            mask="+44 99 9999 9999"
                                            tag={InputMask}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label>Date Signed</label>

                                        <Input type="date" placeholder={userProfile.date_signed} onChange={onChange} name="date_signed" value={userProfile.date_signed} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label className="text-left font-weight-bold">Division</label>
                                        <select name="division" id="" value={division} className="form-control" onChange={onChange}>
                                            <option value="" disabled>Select</option>
                                            <option value="medical">Medical/Health Sector</option>
                                            <option value="industry">Industrial Site</option>
                                            <option value="commercial">Commercial</option>
                                            <option value="pffice">Office Cleaning</option>
                                            <option value="institution">Institution</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label className="text-left font-weight-bold">Status</label>

                                        <select name="status" value={status} onChange={onChange} id="" className="form-control">
                                            <option value="" disabled>Select</option>
                                            <option value="0">Active</option>
                                            <option value="1">Pending</option>
                                            <option value="2">Terminated</option>

                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label className="text-left font-weight-bold">Service Team</label>

                                        <Input type="text" placeholder={userProfile.service_team} onChange={onChange} name="service_team" value={service_team} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label className="text-left font-weight-bold">Invoice Date</label>

                                        <Input type="date" placeholder={userProfile.invoice_date} onChange={onChange} name="invoice_date" value={invoice_date} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label className="text-left font-weight-bold">Visits</label>

                                        <select name="visits" value={visits} id="" className="form-control">
                                            <option value="" disabled>Select</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="thrice/week">three times a week</option>
                                            <option value="twice/week">two times a week</option>
                                            <option value="daily">daily</option>
                                            <option value="once/month">once a month</option>
                                            <option value="fortnightly">fortnightly</option>

                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup className="text-left">
                                        <label className="text-left font-weight-bold">Choose Files</label>
                                        <input
                                            type="file"
                                            name="file"
                                            multiple
                                            onChange={e => handleImageChange(e)}
                                            style={{ opacity: '1', position: 'relative' }}
                                            accept="image/jpeg, image/png, application/pdf"
                                        />
                                        {/* {
                                            userProfile?.attachment?.includes('.pdf')
                                            &&
                                            uploadedFile === null
                                            &&
                                            <a target="_blank" href={`https://api.eagleiq.online/${userProfile?.attachment}`}>See Attachment</a>
                                        } */}
                                    </FormGroup>
                                    {/* {
                                        uploadedFile === null && !userProfile?.attachment?.includes('.pdf')
                                            ? (
                                                <img
                                                    src={`https://api.eagleiq.online/${userProfile?.attachment}`}
                                                    alt="Image"
                                                    width={200}
                                                />
                                            )
                                            :
                                            uploadedFile != null && !file?.name?.includes('.pdf')
                                                ? (
                                                    <img
                                                        src={uploadedFile}
                                                        alt="Image"
                                                        width={200}
                                                    />
                                                )
                                                :
                                                null
                                    } */}
                                    {
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {userProfile?.attachments?.map((fileItem, index) => (
                                                <div key={index} style={{ margin: '10px' }}>
                                                    {!fileItem.filename.includes('.pdf') ? (
                                                        <img
                                                            src={`https://api.eagleiq.online/${fileItem.path}`}
                                                            alt={`Uploaded Image ${index}`}
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    ) : (
                                                        <div>
                                                            <a href={`https://api.eagleiq.online/${fileItem.path}`} target="_blank">See attachment</a>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    {
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {uploadedFile?.map((fileItem, index) => (
                                                <div key={index} style={{ margin: '10px' }}>
                                                    {fileItem.preview ? (
                                                        <img
                                                            src={fileItem.preview}
                                                            alt={`Uploaded Image ${index}`}
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    ) : (
                                                        <div>
                                                            <span style={{ fontWeight: 'bold' }}>{fileItem.file.name} selected</span>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </Col>
                                <Col md="12">
                                    <FormGroup className="text-left">
                                        <label htmlFor="" className="text-left font-weight-bold">Notes</label>
                                        <textarea name="" id="" cols="30" value={userProfile?.notes} onChange={onChange} rows="10" className="form-control border rounded">
                                            {userProfile.notes}
                                        </textarea>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit" onClick={toggleModalClassic2} >
                                    Update
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>

        </>
    );
}
const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    isLoading: state.profileReducer.isLoading,
    sweetAlert: state.profileReducer.sweetAlert,
    clientAddedMsg: state.profileReducer.newClient.message,
    deletedMsg: state.profileReducer.deletedClient.message,


})

export default connect(mapStateToProps, { getAllClients, updateClient, addNewClient, deleteClient, removeSweetAlert })(ManageClients);






















