
import React, { useEffect, useMemo, useRef, useState } from "react";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    FormText,
    Row,
    Col,
} from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import "./notes.css"

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

import avatar from "assets/img/ryan.jpg";
import { connect } from "react-redux";
import { addNotes } from "actions/notesActions";
import { getAllNotes } from "actions/notesActions";

var psTable;

function AddClient(props) {
    const { user, selectedJobData, addNotes, getAllNotes, notes, addNote } = props
    const [message, setMessage] = useState("")
    const [file, setFile] = useState(null)

    const scrollDiv = useRef(null);
    const messageEl = useRef(null);

    useEffect(() => {
        if (scrollDiv.current)
            psTable = new PerfectScrollbar(scrollDiv.current);
    })

    useEffect(() => {
        localStorage.getItem("jobId") !== "" && getAllNotes(localStorage?.getItem("jobId"))
        // alert(localStorage.getItem("jobId"))
    }, [])


    useEffect(() => {
        if (messageEl) {
            messageEl.current.addEventListener('DOMNodeInserted', event => {
                const { currentTarget: target } = event;
                target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
            });
        }
    }, [])



    useEffect(() => {
        getAllNotes(localStorage?.getItem("jobId"))
    }, [addNote])

    const handleUploadFile = (e) => {
        setFile(e.target.files[0])
    }

    const handleSendMessage = () => {
        const data = {
            note: message,
            senderId: user?.id,
            jobId: localStorage.getItem("jobId"),
            attachments: file
        }
        addNotes(data)
        setMessage("")
        getAllNotes(localStorage.getItem("jobId"))
    }

    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Card>
                    <CardHeader className="">
                        <div className="d-flex align-items-center  border-bottom p-3">
                            <img src={avatar} width="40" style={{ clipPath: 'circle()' }} alt="" />
                            <p className="font-weight-bold align-middle align-items-center ml-3 mt-3">{`${user?.fname || ""} ${user?.lname || ""}`}</p>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <div className="notesContainer" style={{ height: "300px", overflowY: "auto", padding: "0 16px", scrollbarGutter: "stable" }} ref={messageEl} >

                            {
                                notes?.notes?.map(note => {
                                    let isMyMessage = false
                                    let senderName = "";
                                    // if (note?.sender == user?.id) {
                                    //     isMyMessage = true
                                    // }
                                    if (note?.sender_type == 0) {
                                        senderName = `${note?.user?.fname} ${note?.user?.lname}`
                                        isMyMessage = true
                                    } else {
                                        senderName = `${note?.admin?.fname} ${note?.admin?.lname}`
                                        isMyMessage = false
                                    }
                                    return (

                                        <div className={`${isMyMessage ? "text-left" : "text-right"} my-4`} key={note?.id}>
                                            <p style={{ display: "inline-block", textAlign: "left", marginBottom: 0 }} className=" bg-light rounded p-2"> {note?.attachments && <a href={`https://devalton.esquall.com/${note?.attachments}`} download>File</a>} {note?.attachments && <br />} {note?.notes}</p> <br />
                                            <small className="m-2 mt-5 text-secondary">{senderName}</small>
                                        </div>
                                    )
                                })
                            }

                            {/* <div className="text-left my-4">
                            <span className=" bg-light rounded p-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint .</span> <br />
                            <small className="m-2 mt-5 text-secondary">Sent By Client</small>
                        </div> */}

                            {/* <div className="text-right my-4">
                            <span className=" border  rounded p-2">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod, nulla?</span>
                        </div>
                        <div className="text-left my-4">
                            <span className=" bg-light rounded p-2">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint .</span> <br />
                            <small className="m-2 mt-5 text-secondary">Sent By Staff</small>

                        </div>

                        <div className="text-right my-4">
                            <span className=" border  rounded p-2">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quod, nulla?</span>
                        </div> */}
                        </div>
                    </CardBody>
                    <CardFooter className="bg-light mt-5">
                        <div className="d-flex align-items-center p-1">
                            <input type="file" id="upload-document" hidden onChange={handleUploadFile} />
                            <label htmlFor="upload-document">
                                <i className="fas fa-plus-circle m-2 text-info"></i>
                            </label>

                            <Input placeholder="Message" className="m-2" name="message" value={message} onChange={e => setMessage(e.target.value)} />
                            <Button size="sm" className="m-2" color="info" onClick={handleSendMessage}>Send</Button>

                        </div>
                    </CardFooter>
                </Card>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    user: state?.authReducer?.user,
    selectedJobData: state?.notesReducer?.selectedJobData,
    notes: state?.notesReducer?.allNotes,
    addNote: state?.notesReducer?.addNote
})

export default connect(mapStateToProps, { addNotes, getAllNotes })(AddClient);
// export default AddClient;
