import {
  addNewSite,
  deleteSite,
  getAllClients,
  getAllSites,
  getUserProfile,
  removeSweetAlert,
  updateSite,
} from "actions/profileActions";
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import InputMask from "react-input-mask";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import { getAllAreas } from "actions/profileActions";
import { getAllBuildings } from "actions/profileActions";
import AddAreaModal from "components/Modals/AddAreaModal";
import AddBuildingModal from "components/Modals/AddBuildingModal";
import store from "store";
import { emptyUserProfile } from "actions/profileActions";
import { GET_SINGLE_SITE_DETAIL } from "actions/types";

var positions;

const RegularMap = ({ mapLocation }) => {
  console.log("🚀 ~ RegularMap ~ mapLocation:", mapLocation);
  const mapRef = React.useRef(null);
  // const [location, setLocation] = useState({ lat: -33.8688, lng: 151.2073 });

  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;

    const myLatlng = new google.maps.LatLng(mapLocation.lat, mapLocation.lng);
    const mapOptions = {
      zoom: 13,
      center: myLatlng,
      scrollwheel: false,
    };

    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      animation: google.maps.Animation.DROP,

      title: "Set Location",
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        fillColor: "red",
        fillOpacity: 1.0,
        strokeColor: "white",
        strokeWeight: 5,
        scale: 10,
      },
    });

    let infoWindow = new google.maps.InfoWindow({
      content: `Location: ${mapLocation.lat}, ${mapLocation.lng}`,
      position: myLatlng,
    });
    infoWindow.open(map, marker);

    map.addListener("click", (mapsMouseEvent) => {
      const newLocation = mapsMouseEvent.latLng;
      // setLocation(newLocation);
      infoWindow.close();
      marker.setPosition(newLocation);
      infoWindow.setContent(
        `Location: ${newLocation.lat()}, ${newLocation.lng()}`
      );
      infoWindow.open(map, marker);
    });
  }, [mapLocation]);

  const handleLocationChange = (newLocation) => {
    // setLocation(newLocation);
  };

  return (
    <>
      <div style={{ height: `280px` }} ref={mapRef} />
    </>
  );
};

const { SearchBar } = Search;

const UpdateSite = ({
  getAllSites,
  allSites,
  allClients,
  deleteSite,
  updateSite,
  getUserProfile,
  user,
  getAllClients,
  removeSweetAlert,
  isLoading,
  deletedSite,
  updatedSite,
  addedSite,
  sweetAlert,
  getAllAreas,
  getAllBuildings,
  allAreas,
  allBuildings,
  singleSiteDetail,
  emptyUserProfile,
}) => {
  const dispatch = useDispatch();

  const [mapLocations, setMapLocations] = useState(positions);
  const [open, setOpen] = useState(false);
  const [buildingOpen, setBuildingOpen] = useState(false);
  const [modalClassic2, setModalClassic2] = React.useState(false);

  // TODO: GOOGLE AUTOCOMPLETE API
  const inputRef = useRef(null);
  const [isLoadingAPI, setIsLoadingAPI] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [mapLocation, setMapLocation] = useState({
    lat: -33.8688,
    lng: 151.2073,
  });
  const [siteProfile, setSiteProfile] = useState({});
  const [formData, setFormData] = useState({
    client_id: "",
    company_id: "1",
    site_name: "",
    emergency_contact_no: "",
    emergency_phone_no: "",
    out_of_hours: "",
    alarm_call: "",
    lat: mapLocations ? mapLocations.lat : "",
    lan: mapLocations ? mapLocations.lng : "",
    location: "",
    town: "",
    post_code: "",
    address: "",
    email: "",
    client_contact: "",
    file: [],
    buildings: accesses,
  });

  useEffect(() => {
    console.log("USEEFFECT CALLED ===>");
    const initAutocomplete = () => {
      const google = window.google;
      const options = {
        fields: ["address_components", "geometry", "icon", "name"],
      };
      console.log(
        "🚀 ~ initAutocomplete ~ inputRef.current:",
        inputRef.current
      );
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      console.log("🚀 ~ initAutocomplete ~ autocomplete:", autocomplete);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        setMapLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      });

      autocomplete.addListener("place_changed", () => {
        setSuggestions([]);
      });

      autocomplete.addListener("input", () => {
        const input = autocomplete.getPlace();
        const predictions = autocomplete.getPlacePredictions();
        setSuggestions(predictions);
        console.log("CALLED ROCKET ==>");
      });
    };

    if (window.google && window.google.maps && window.google.maps.places) {
      initAutocomplete();
      setIsLoadingAPI(false);
    } else {
      console.error("Google Maps API not loaded yet");
    }
  }, []);

  // TODO: END

  useEffect(() => {
    setMapLocations(positions);
  }, [positions]);

  const [alert, setAlert] = React.useState(null);
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    if (sweetAlert) {
      successAlert();
    } else {
      hideAlert();
    }
  }, [sweetAlert]);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
      >
        {deletedSite
          ? deletedSite
          : updatedSite
          ? updatedSite
          : addedSite
          ? addedSite
          : null}
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
    removeSweetAlert();
  };

  const history = useHistory();
  const [modalClassic, setModalClassic] = React.useState(false);
  const toggleModalClassic = () => {
    setModalClassic(!modalClassic);
  };
  const [modalClassic1, setModalClassic1] = React.useState(false);
  const toggleModalClassic1 = () => {
    setModalClassic1(!modalClassic1);
  };

  const toggleModalClassic2 = () => {
    setModalClassic2(!modalClassic2);
  };

  const [sites, setSites] = useState([]);

  useEffect(() => {
    getAllSites();
  }, []);

  useEffect(() => {
    if (allSites.length !== 0) {
      setSites(allSites.sites.reverse());
    } else {
      return null;
    }
  });

  useEffect(() => {
    getAllClients();
  }, []);

  var [areas, setAreas] = useState([]);

  const [buildingareas, setbuildingareas] = useState([]);
  const addNewArea = () => {
    setOpen(true);
  };
  const changeAreaName = (index) => (e) => {
    let newArr = [...buildingareas];
    newArr[index]["name"] = e.target.value;
    setbuildingareas(newArr);
  };

  var [accesses, setAccesses] = useState([]);
  var [securityAccess, setSecurityAccess] = useState([]);
  const addNewAccess = () => {
    setBuildingOpen(true);
  };
  const changeAccessName = (index) => (e) => {
    let newArr = [...accesses];
    newArr[index]["name"] = e.target.value;
    setAccesses(newArr);
  };

  var {
    client_id,
    company_id,
    site_name,
    emergency_contact_no,
    out_of_hours,
    emergency_phone_no,
    alarm_call,
    lat,
    lan,
    location,
    town,
    post_code,
    address,
    email,
    client_contact,
    file,
    buildings,
  } = formData;
    console.log("🚀 ~ file:", file)
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onChange1 = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });

  useEffect(() => {
    if (client_id === "" || client_id === null || client_id === undefined) {
      return null;
    } else {
      var formdata = new FormData();
      formdata.append("id", client_id);
      getUserProfile(formdata);
    }
  }, [client_id]);

  const onSubmit = (e) => {
    e.preventDefault();

    const areasId = buildingareas?.map((data) => data?.value);
    const buildingsId = accesses?.map((data) => data?.value);

    var raw = {
      site_id: JSON.stringify(siteProfile.id),
      company_id: "1",
      client_id: client_id,
      site_name: site_name,
      emergency_contact_no: emergency_contact_no,
      emergency_phone_no: emergency_phone_no,
      out_of_hours: out_of_hours,
      alarm_call: alarm_call,
      lat: mapLocation.lat,
      lan: mapLocation.lng,
      location: inputRef.current.value,
      town: !user ? siteProfile.client.town : user.town,
      post_code: post_code,
      address: !user ? siteProfile.client.address : user.address,
      email: !user ? siteProfile.client.email : user.email,
      client_contact: !user ? siteProfile.client.mobile_no : user.mobile_no,
      file: [...file, ...newFiles],
      areas: areasId,
      buildings: buildingsId,
    };
    updateSite(raw, history);
  };

  function iconFormatter(cell, row) {
    if (row.client) {
      return <span> {row.client.fname + " " + row.client.lname} </span>;
    } else {
      return null;
    }
  }

  const toggleModal = () => {
    setOpen(!open);
  };

  const buildingToggleModal = () => {
    setBuildingOpen(!buildingOpen);
  };

  const selectedAreas = siteProfile?.areas?.map((data) => ({
    value: data?.get_area?.id,
    label: data?.get_area?.name,
  }));

  const selectedBuildings = siteProfile?.buildings?.map((data) => ({
    value: data?.get_building?.id,
    label: data?.get_building?.name,
  }));

  const [uploadedFile, setUploadedFile] = useState([]);
  const [newFiles, setNewFiles] = useState([]);

  const handleImageChange = (e) => {
    const files = e.target.files;

    const newFiles = Array.from(files).map((file) => ({
      file,
      preview: file.type.startsWith("image/")
        ? URL.createObjectURL(file)
        : null,
    }));

    setUploadedFile((prevFiles) => [...prevFiles, ...newFiles]);
    // setFormData({ ...formData, file: [...formData.file, ...files] });
    setNewFiles([...newFiles, ...files]);
  };

  useEffect(() => {
    setFormData({
      client_id: siteProfile?.client?.id,
      company_id: "1",
      site_name: siteProfile?.site_name,
      emergency_contact_no: siteProfile?.emergency_contact_no,
      emergency_phone_no: siteProfile?.emergency_phone_no,
      out_of_hours: siteProfile?.out_of_hours,
      alarm_call: siteProfile?.alarm_call,
      lat: siteProfile?.lat,
      lan: siteProfile?.lan,
      location: siteProfile?.location,
      town: siteProfile?.town,
      post_code: siteProfile?.post_code,
      address: siteProfile?.address,
      email: siteProfile?.email,
      client_contact: siteProfile?.client?.mobile_no,
      file: siteProfile?.attachments,
      buildings: siteProfile?.buildings,
    });
    setbuildingareas(selectedAreas);
    setAccesses(selectedBuildings);
    setMapLocation({
        lat: siteProfile?.lat !== null ? siteProfile?.lat : -33.8688,
        lng: siteProfile?.lan !== null ? siteProfile?.lan : 151.2073,
    })
    inputRef.current.value = siteProfile?.location
  }, [siteProfile]);

  useEffect(() => {
    getAllAreas();
    getAllBuildings();
  }, []);

  useEffect(() => {
    setSiteProfile(singleSiteDetail);
  }, [singleSiteDetail]);

  return (
    <>
      <PanelHeader size="sm" />
      <div className="content">
        {alert}
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Update Site</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={(e) => onSubmit(e)}
                  encType="multipart/form-data"
                >
                  <Row>
                    <Col md="6">
                      <label>Company Name</label>
                      <FormGroup>
                        <select
                          name="client_id"
                          className="form-control"
                          type="select"
                          value={client_id}
                          onChange={(e) => onChange(e)}
                        >
                          <option value="">Select</option>
                          {allClients.clients.map((client) => (
                            <option value={client.id}>
                              {client.company_name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Contact</label>
                      <FormGroup>
                        <Input
                          type="text"
                          value={user ? user.phone_no : ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <label>Address</label>
                      <FormGroup>
                        <Input
                          type="text"
                          value={user ? user.address : ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <label>Town</label>
                      <FormGroup>
                        <Input
                          type="text"
                          value={user ? user.town : ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <label>Post Code</label>
                      <FormGroup>
                        <Input
                          type="text"
                          value={user ? user.post_code : ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <label>Telephone</label>
                      <FormGroup>
                        <Input
                          type="text"
                          value={user ? user.mobile_no : ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <label>Email</label>
                      <FormGroup>
                        <Input
                          type="email"
                          value={user ? user.email : ""}
                          readOnly
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Site Name</label>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={siteProfile.site_name}
                          onChange={onChange}
                          name="site_name"
                          value={site_name}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Location</label>
                      <FormGroup>
                        <div>
                          {isLoadingAPI && <p>Loading Google Places API...</p>}
                          <input
                            type="text"
                            id="my-place-input"
                            // value={location}
                            ref={inputRef}
                            placeholder="Enter a location"
                            style={{
                              width: "100%",
                              padding: "4px",
                              borderRadius: "50px",
                              outline: "none",
                            }}
                          />
                          <ul>
                            {suggestions.map((suggestion) => (
                              <li key={suggestion.id}>
                                {suggestion.description}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Post Code</label>
                      <FormGroup>
                        <Input
                          type="text"
                          value={post_code}
                          name="post_code"
                          onChange={onChange}
                          mask="aa99 9aa"
                          tag={InputMask}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Emergency Contact Name</label>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={siteProfile.emergency_contact_no}
                          value={emergency_contact_no}
                          name="emergency_contact_no"
                          onChange={onChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Emergency Phone #</label>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={siteProfile.emergency_phone_no}
                          value={emergency_phone_no}
                          name="emergency_phone_no"
                          onChange={onChange}
                          mask="0 99 9999 9999"
                          tag={InputMask}
                          onCopy={(e) => {
                            e.clipboardData.setData(
                              "text/plain",
                              e.target.value.substring(1)
                            );
                            e.preventDefault();
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Out of Hours Telephone</label>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={siteProfile.out_of_hours}
                          value={out_of_hours}
                          name="out_of_hours"
                          onChange={onChange}
                          mask="0 99 9999 9999"
                          tag={InputMask}
                          onCopy={(e) => {
                            e.clipboardData.setData(
                              "text/plain",
                              e.target.value.substring(1)
                            );
                            e.preventDefault();
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <label>Alarm Call Out Number</label>
                      <FormGroup>
                        <Input
                          type="text"
                          placeholder={siteProfile.alarm_call}
                          value={alarm_call}
                          name="alarm_call"
                          onChange={onChange}
                          mask="0 99 9999 9999"
                          tag={InputMask}
                          onCopy={(e) => {
                            e.clipboardData.setData(
                              "text/plain",
                              e.target.value.substring(1)
                            );
                            e.preventDefault();
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup className="text-left">
                        <label>File Upload</label>

                        <FormGroup className="">
                          <input
                            type="file"
                            style={{
                              opacity: "1",
                              position: "relative",
                            }}
                            name="file"
                            multiple
                            accept="image/jpeg, image/png, application/pdf"
                            onChange={(e) => handleImageChange(e)}
                            className="form-control"
                          />
                        </FormGroup>
                      </FormGroup>
                      {
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {formData?.file?.map((fileItem, index) => (
                            <div key={index} style={{ margin: "10px" }}>
                              {!fileItem.filename.includes(".pdf") ? (
                                <img
                                  src={`https://api.eagleiq.online/${fileItem.path}`}
                                  alt={`Uploaded Image ${index}`}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              ) : (
                                <div>
                                  <a
                                    href={`https://api.eagleiq.online/${fileItem.path}`}
                                    target="_blank"
                                  >
                                    See attachment
                                  </a>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      }
                      {
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {uploadedFile?.map((fileItem, index) => (
                            <div key={index} style={{ margin: "10px" }}>
                              {fileItem.preview ? (
                                <img
                                  src={fileItem.preview}
                                  alt={`Uploaded Image ${index}`}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              ) : (
                                <div>
                                  <span style={{ fontWeight: "bold" }}>
                                    {fileItem.file.name} selected
                                  </span>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      }
                    </Col>
                    <Col
                      md="6"
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      <label>
                        Areas{" "}
                        <i
                          className="fas fa-plus-circle text-info"
                          onClick={() => addNewArea()}
                        ></i>
                      </label>
                      <Select
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={buildingareas}
                        onChange={(e) => {
                          if (areas?.length < 10) {
                            setbuildingareas(e);
                          }
                        }}
                        options={allAreas?.areas?.map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))}
                      />
                    </Col>

                    <Col
                      md="6"
                      style={{
                        marginBottom: "16px",
                      }}
                    >
                      <label>
                        Building Access & Security{" "}
                        <i
                          className="fas fa-plus-circle text-info"
                          onClick={() => addNewAccess()}
                        ></i>
                      </label>
                      <Select
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={accesses}
                        onChange={(e) => {
                          if (securityAccess?.length < 5) {
                            setAccesses(e);
                          }
                        }}
                        options={allBuildings?.buildings?.map((data) => ({
                          value: data?.id,
                          label: data?.name,
                        }))}
                      />
                    </Col>

                    <Col md="12">
                      <RegularMap mapLocation={mapLocation} />
                    </Col>
                  </Row>

                  <div className="text-center">
                    {isLoading ? (
                      <div className="spinner-border text-info" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <Button className="btn-fill" color="info" type="submit">
                        Update
                      </Button>
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <AddAreaModal open={open} toggleModal={toggleModal} />

      <AddBuildingModal open={buildingOpen} toggleModal={buildingToggleModal} />
    </>
  );
};

const mapStateToProps = (state) => ({
  allSites: state.profileReducer.allSites,
  user: state.profileReducer.userProfile.user,
  allClients: state.profileReducer.allClients,
  sweetAlert: state.profileReducer.sweetAlert,
  isLoading: state.profileReducer.isLoading,
  deletedSite: state.profileReducer.deletedSite.message,
  addedSite: state.profileReducer.newSite.message,
  updatedSite: state.profileReducer.updatedSite.message,
  allAreas: state.profileReducer.allAreas,
  allBuildings: state.profileReducer.allBuildings,
  singleSiteDetail: state.profileReducer.singleSiteDetail,
});

export default connect(mapStateToProps, {
  getAllSites,
  addNewSite,
  updateSite,
  emptyUserProfile,
  deleteSite,
  getUserProfile,
  getAllAreas,
  getAllBuildings,
  getAllClients,
  removeSweetAlert,
})(UpdateSite);
