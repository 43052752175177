
import {
    USER_LOADED,
    AUTH_ERROR,
    SET_ALERT,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    CHANGE_LOADING_AUTH,
    CHANGE_IS_AUTHENTICATED,
} from './types';

import setAuthToken from '../utils/setAuthToken.js'
import { setAlert } from './alertActions';

// STATE MANAGMENT
export const ChangeIsAuthenticated = (val) => {
    return async (dispatch) => {
        dispatch({ type: CHANGE_IS_AUTHENTICATED, payload: val })
    }
}
// Load user
export const loadUser = () => dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token)
        var myHeaders = new Headers();
        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6ImRndWx4UHdlTFZNT0l1T0F4VkxxSHc9PSIsInZhbHVlIjoiMzZVU09zT3paaGs0OGYxbkZnSkZqN0tIQ2Q1cmxLT0R0d29QN0h3QVB5Y0NpK1YvZHJkUGhKOGNVTTFUcDJRQ0YvUHR4bVBMREV4UTBkT0h5UnkwRmxHZnk4WjBCVW1tZE1MdUVrbnVtZ2laWVFncm5meThtMmlmei9UQ011ZW4iLCJtYWMiOiI4ZGQyZjJjYzkwODlhZDI4NDIyYmI1N2RlODlhZjA3YTU5NmNmOTg2OGMzMzMxMjdjNzMxMGU4YWQxZTRiN2UxIn0%3D; laravel_session=eyJpdiI6InJKb0N4NDJwWkRMR2pDdXk4d3NpN3c9PSIsInZhbHVlIjoiZkx4eFVJaEMxLzdCTTZNNVpPaHNsWHI3cTNLclgycFVYRjU5TDZHK3FxM1dGcUlibUo3VkMxWmNmQUlBQWxoRGxWYjlqZVBUOVlsWW9kdFJEWG4rY3FuZXRUc0FSYTkyemFhbGdkdGl0QWVzZjZsbWgySklmNmxzUEhKamFRaW0iLCJtYWMiOiI3YWU1MjBiMjQ4YWY0MTFhMGY4ZjgzZDYxYmE1YzIzM2U3Yzc1MGFiYzRlMzQ3YTRmMDIwMmUwZjZjZjU5NThhIn0%3D");
        var formdata = new FormData();
        formdata.append("token", `${localStorage.token}`);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch("https://api.eagleiq.online/api/admin/profile", requestOptions)
            .then(response => response.json())
            .then(data => {
                dispatch({ type: USER_LOADED, payload: data })
            })
            .catch(error => dispatch({ type: AUTH_ERROR }));
    }
}

export const login = ({ email, password }) => dispatch => {
    dispatch({ type: CHANGE_LOADING_AUTH, payload: true })

    var formdata = new FormData()
    formdata.append("email", email)
    formdata.append("password", password)

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    fetch("https://api.eagleiq.online/api/admin/login", requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                dispatch(
                    setAlert(data.error, 'danger')
                )
            } else {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: data
                })
                dispatch(loadUser())
            }
            dispatch({ type: CHANGE_LOADING_AUTH, payload: false })
        })
        .catch(error => dispatch({
            type: LOGIN_FAIL
        }));
}
export const logout = () => {
    return async (dispatch) => {
        dispatch({ type: LOGOUT })

        var myHeaders = new Headers();
        myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6ImhVRXcyOVRtVklQNFFTZkpXR2JiN2c9PSIsInZhbHVlIjoiWXhSV1JXZjFPMW1Yd0kzV0lLMXEzU2NhUlRhd0ZRbzd1NSttRUkwYWt0TFNLQ1RVdzhRZEU3RDN3bWJrZ2w2ZXlsc0RoS09jVUVjbEpSU3RqVnlOVVdVVW5DV1VZV25MK09vOWlrVWNXQitaUjcwTUJzMDRueVVzMnA0SDdPSXkiLCJtYWMiOiI4OGNmZmE4YmVjMzIzMzE4OTdjMzYyOGM4ODQ0NjY4YTllMGYzNDZlOGM3NDUxNDA4NjE4MWM5MDMyYmNhODBjIn0%3D; laravel_session=eyJpdiI6IjJZS2t1MzF1UEZIeUVpNW9SaUJYK2c9PSIsInZhbHVlIjoiUTZ6cVViaHBKenorUWRPNGFpRE9YbmxzczZMQ3hOajN3alF1V013SHJML01IdjZTSzBDdWhhcUhBeklPMm1STjkvWDJwcWYvUTNGSkROSTN5RE85WGsvZ2NYa2Evc2F1dG9xaHRvc3RhclJSUnZPcHNoSEJmSDZkd2lzeG1QOHYiLCJtYWMiOiJiMWM4N2NjMmZmNzFiMDA4YTFiMmM3NmI2YzY3ODNiYWIxZjY1YjhlMTFiZDkwNmE4NzIyZTM5NWNiMDliMzlmIn0%3D");

        var formdata = new FormData();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };


        let data = await fetch("https://api.eagleiq.online/api/admin/logout", requestOptions)
            .then(response => response.json())
            .catch(error => dispatch({
                type: LOGIN_FAIL
            }));

        console.info("LOGOUT DATA")
        console.info(data)
    }
}