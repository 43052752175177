
import React from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getBaseURl } from "actions/profileActions";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Form,
    Input,
    FormGroup,
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";

const MyProfile = ({ user }) => {
    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                <Row>
                    <Col md="8" className="">
                        <Card>
                            <CardHeader>
                                <h5 className="title">My Profile</h5>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>

                                        <Col md="6">
                                            <FormGroup>
                                                <label>First Name</label>
                                                <Input

                                                    placeholder=""
                                                    type="text"
                                                    readOnly
                                                    defaultValue={user.fname}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Last Name</label>
                                                <Input

                                                    placeholder=""
                                                    type="text"
                                                    readOnly
                                                    defaultValue={user.lname}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <label>Address</label>
                                                <Input

                                                    placeholder=""
                                                    type="text"
                                                    readOnly
                                                    defaultValue={user.address}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="pr-1" md="4">
                                            <FormGroup>
                                                <label>City</label>
                                                <Input
                                                    readOnly
                                                    placeholder=""
                                                    type="text"
                                                    defaultValue={user.town}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="px-1" md="4">
                                            <FormGroup>
                                                <label>Country</label>
                                                <Input

                                                    placeholder=""
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="pl-1" md="4">
                                            <FormGroup>
                                                <label>Postal Code</label>
                                                <Input type="number"
                                                    readOnly
                                                    placeholder=""
                                                    defaultValue={user.post_code}


                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Contact #</label>
                                                <Input type="number"
                                                    readOnly
                                                    defaultValue={user.phone_no}


                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="4">
                                            <FormGroup>
                                                <label>Contact Name</label>
                                                <Input type="text" />
                                            </FormGroup>
                                        </Col> */}
                                        <Col md="6">
                                            <FormGroup>
                                                <label>Email</label>
                                                <Input type="email"

                                                    readOnly
                                                    defaultValue={user.email}

                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md="12">
                                            <FormGroup>
                                                <label>Notes Box</label>
                                                <Input
                                                    cols="80"
                                                    className="border"
                                                    rows="4"
                                                    type="textarea"
                                                />
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    {/* <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>About Me</label>
                        <Input
                          cols="80"
                          defaultValue="Lamborghini Mercy, Your chick she so thirsty, I'm in
                            that two seat Lambo."
                          placeholder="Here can be your description"
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                                    {/* <div className="text-right">
                                        <Link className="btn btn-sm btn-info" to="/admin/edit-profile">Update</Link>
                                    </div> */}
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md="4">
                        <Card className="card-user">
                            <div className="image">
                                <img alt="..." src={require("assets/img/bg5.jpg").default} />
                            </div>
                            <CardBody>
                                <div className="author">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className="avatar border-gray"
                                            src={`https://devalton.esquall.com/${user?.pic}`}
                                        />
                                        <h5 className="title">{user.fname + ' ' + user.lname}</h5>
                                    </a>
                                    <p className="description">{user.email}</p>
                                </div>
                                {/* <p className="description text-center">
                  {'"'}Lamborghini Mercy <br />
                  Your chick she so thirsty <br />
                  I'm in that two seat Lambo{'"'}
                </p> */}
                            </CardBody>
                            {/* <hr />
              <div className="button-container">
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="neutral"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  size="lg"
                >
                  <i className="fab fa-google-plus-square" />
                </Button>
              </div> */}
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    user: state.authReducer.user
})

export default connect(mapStateToProps, { getBaseURl })(MyProfile);
