
import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Select from "react-select";
import { Link, useHistory } from 'react-router-dom'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { connect } from 'react-redux';
import {
    getAllClients,
    addNewClient,
    updateClient,
    deleteClient,
    getAllLeaveRequests,
    changeLeaveStatus,
    removeSweetAlert
} from "actions/profileActions";
import SweetAlert from "react-bootstrap-sweetalert";
import {

    Card,
    Container, Col,
    Row, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button, FormGroup, CardHeader, Form, Input, Label, UncontrolledTooltip

} from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.js";

const LeaveType = [
    'Statutory holiday',
    'Maternity/Paternity Leave',
    'Annual Leave',
    'Stick note',
]

const Reason = [
    'Arranged',
    'Required',
    'Not Required',
]

const dataTable = [
    {
        employee_name: 'Harry Styles',
        role: 'Manager',
        leave_request: '22/02/2020'

    }
]

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length " id="datatable-basic_length">
            <label className="d-flex justify-content-start">
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});


const { SearchBar } = Search;


const LeaveRequests = ({ getAllClients, allClients, addNewClient, updateClient, deleteClient, leaveRequests, getAllLeaveRequests, changeLeaveStatus, user, removeSweetAlert, sweetAlert, isLoading, leaveStatus }) => {

    // Sweet Alert

    const [alert, setAlert] = React.useState(null);
    React.useEffect(() => {
        return function cleanup() {
            var id = window.setTimeout(null, 0);
            while (id--) {
                window.clearTimeout(id);
            }
        };
    }, []);

    useEffect(() => {
        if (sweetAlert) {
            successAlert()


        } else {
            hideAlert()
        }
    }, [sweetAlert])

    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Success!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
            >
                {leaveStatus ? leaveStatus : null}
            </SweetAlert>
        );
    }
    const hideAlert = () => {
        setAlert(null);
        removeSweetAlert()
    };






    const history = useHistory()
    const [modalClassic, setModalClassic] = React.useState(false);
    const toggleModalClassic = () => {
        setModalClassic(!modalClassic);
    };

    const [modalClassic1, setModalClassic1] = React.useState(false);
    const toggleModalClassic1 = () => {
        setModalClassic1(!modalClassic1);
    };
    const [modalClassic2, setModalClassic2] = React.useState(false);
    const toggleModalClassic2 = () => {
        setModalClassic2(!modalClassic1);
    };


    const [clients, setClients] = React.useState([]);
    const [leaves, setLeaves] = React.useState([]);

    const [userProfile, setUserProfile] = useState([])



    const setModal = (row) => {
        toggleModalClassic1()
        setUserProfile(row)


    }


    const setUpdateProfile = (row) => {
        toggleModalClassic2()
        setUserProfile(row)


    }

    useEffect(() => {
        getAllClients();
    }, [])



    useEffect(() => {
        if (allClients.length !== 0) {
            setClients(allClients.clients)


        } else {
            return null
        }
        if (leaveRequests.length !== 0) {
            setLeaves(leaveRequests.leaveRequests)


        } else {
            return null
        }
    })


    var [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        address: '',
        post_code: '',
        town: '',
        phone_no: '',
        mobile_no: '',
        password: '',
        start_date: '',
        end_date: '',



    })

    var { fname, lname, address, post_code, mobile_no, phone_no, email, town, password, start_date, end_date } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })




    const updateStaffSubmit = e => {
        e.preventDefault();

        if (fname === '') {
            fname = userProfile.fname
        }
        if (lname === '') {
            lname = userProfile.lname
        }
        if (address === '') {
            address = userProfile.address
        }
        if (post_code === '') {
            post_code = userProfile.post_code
        }
        if (mobile_no === '') {
            mobile_no = userProfile.mobile_no
        }
        if (phone_no === '') {
            phone_no = userProfile.phone_no
        }
        if (email === '') {
            email = userProfile.email
        }
        if (town === '') {
            town = userProfile.town
        }




        var formData = new FormData();
        formData.append("client_id", userProfile.id);
        formData.append("fname", fname);
        formData.append("lname", lname);
        formData.append("address", address);
        formData.append("post_code", post_code);
        formData.append("mobile_no", mobile_no);
        formData.append("phone_no", phone_no);
        formData.append("email", email);
        formData.append("town", town);
        updateClient(formData, history)

        setFormData({

            fname: '',
            lname: '',
            email: '',
            address: '',
            post_code: '',
            town: '',
            phone_no: '',
            mobile_no: '',
            password: ''

        })

    }






    function nameFormatter(cell, row) {
        if (row.employee) {
            return (
                <span>   {row.employee.fname + ' ' + row.employee.lname} </span>
            );

        } else {
            return null
        }


    }
    function statusFormatter(cell, row) {


        return (
            <span>   {cell === 0 ? 'Pending' : cell === 1 ? 'Accepted' : cell === 2 ? 'Rejected' : null} </span>
        );
    }



    return (
        <>
            <PanelHeader size="sm" />
            <div className="content">
                {alert}


                <Row>
                    <div className="col">
                        <Card>


                            <Container>


                                <ToolkitProvider
                                    data={leaves}
                                    keyField="id"
                                    bootstrap4

                                    columns={[
                                        {
                                            dataField: "employee.fname",
                                            text: "Employee Name",
                                            sort: true,
                                            formatter: nameFormatter

                                        },
                                        {
                                            dataField: "employee.email",
                                            text: "Email",
                                            sort: true,
                                        },

                                        {
                                            dataField: "start_date",
                                            text: "From",
                                            sort: true,
                                        },
                                        {
                                            dataField: "end_date",
                                            text: "To",
                                            sort: true,
                                        },
                                        {
                                            dataField: "reason_id",
                                            text: "Leave Type",
                                            sort: true,
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <p>
                                                        {
                                                            LeaveType[row.reason_id]
                                                        }
                                                    </p>
                                                )
                                            }
                                        },
                                        {
                                            dataField: "reason",
                                            text: "Request Cover",
                                            sort: true,
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <p>
                                                        {
                                                            Reason[row.reason]
                                                        }
                                                    </p>
                                                )
                                            }
                                        },
                                        {
                                            dataField: "status",
                                            text: "Status",
                                            sort: true,
                                            formatter: statusFormatter
                                        },
                                        {
                                            dataField: 'df2',
                                            isDummyField: true,
                                            text: 'Action',
                                            formatter: (cellContent, row) => {
                                                return (
                                                    <h5 className="d-flex justify-content-start">
                                                        {
                                                            row.status === 0 ? (
                                                                <div>
                                                                    <span className="btn btn-success btn-sm m-1" onClick={() => changeLeaveStatus(row.id, 1, history)} >Accept</span>
                                                                    <span className="btn btn-danger btn-sm m-1" onClick={() => changeLeaveStatus(row.id, 2, history)} > Reject</span>
                                                                </div>


                                                            ) : row.status === 1 ? (
                                                                <div>
                                                                    {
                                                                        isLoading ? (
                                                                            <div class="spinner-border text-primary" role="status">
                                                                                <span class="sr-only">Loading...</span>
                                                                            </div>
                                                                        ) : (
                                                                            <span className="m-1"> -</span>
                                                                        )

                                                                    }

                                                                </div>

                                                            ) : row.status === 2 ? (
                                                                <div>
                                                                    {
                                                                        isLoading ? (
                                                                            <div class="spinner-border text-primary" role="status">
                                                                                <span class="sr-only">Loading...</span>
                                                                            </div>
                                                                        ) : (
                                                                            <span className="m-1"> -</span>
                                                                        )
                                                                    }

                                                                </div>
                                                            )


                                                                : null
                                                        }

                                                    </h5>
                                                );

                                            }
                                        }


                                    ]}
                                    search
                                >
                                    {(props) => (
                                        <div className="py-4 ">
                                            <div
                                                id="datatable-basic_filter"
                                                className="dataTables_filter px-4 pb-4 text-right"
                                            >

                                                <label>
                                                    Search:
                                                    <SearchBar
                                                        className="form-control"
                                                        placeholder=""
                                                        {...props.searchProps}
                                                    />
                                                </label>





                                            </div>
                                            <BootstrapTable
                                                {...props.baseProps}
                                                bootstrap4={true}
                                                pagination={pagination}
                                                bordered={false}
                                            />
                                        </div>
                                    )}
                                </ToolkitProvider>
                            </Container>
                        </Card>

                    </div>


                </Row>


                {/* View Profile Modal */}
                <Modal
                    isOpen={modalClassic1}
                    toggle={toggleModalClassic1}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-center uppercase title text-left"
                        toggle={toggleModalClassic1}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <table className="table table-bordered">
                            <tbody>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">First Name</td>
                                    <td className="border-0">{userProfile.fname}</td>
                                    <td className="border-0 font-weight-bold">Last Name</td>
                                    <td className="border-0">{userProfile.lname}</td>

                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Email</td>
                                    <td className="border-0">{userProfile.email}</td>
                                    <td className="border-0 font-weight-bold">Address</td>
                                    <td className="border-0">{userProfile.address}</td>
                                </tr>
                                <tr className="border">
                                    <td className="border-0 font-weight-bold">Phone No</td>
                                    <td className="border-0">{userProfile.phone_no}</td>
                                    <td className="border-0 font-weight-bold">Post Code</td>
                                    <td className="border-0">{userProfile.post_code}</td>
                                </tr>
                            </tbody>
                        </table>

                    </ModalBody>
                    {/* <div className="text-right">
                        <Button color="primary" onClick={toggleModalClassic}>
                            Next
                        </Button>
                    </div> */}

                </Modal>

                {/* Update Staff Modal */}

                <Modal
                    isOpen={modalClassic2}
                    toggle={toggleModalClassic2}
                    className="text-center"
                    size="lg"
                >
                    <ModalHeader
                        className="justify-content-start uppercase title text-left"
                        toggle={toggleModalClassic2}
                        tag="h6"
                    >
                        {userProfile.fname + ' ' + userProfile.lname}
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={e => updateStaffSubmit(e)} encType="multipart/form-data">
                            <Row>

                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> First Name </Label>
                                        <Input type="text" placeholder={userProfile.fname} value={fname} name="fname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Last Name </Label>
                                        <Input type="text" placeholder={userProfile.lname} value={lname} name="lname" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Email </Label>
                                        <Input type="email" placeholder={userProfile.email} value={email} name="email" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Address </Label>
                                        <Input type="text" placeholder={userProfile.address} value={address} name="address" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Post Code </Label>
                                        <Input type="number" placeholder={userProfile.post_code} value={post_code} name="post_code" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Town </Label>
                                        <Input type="text" placeholder={userProfile.town} value={town} name="town" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Phone No </Label>
                                        <Input type="text" placeholder={userProfile.phone_no} value={phone_no} name="phone_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>
                                <Col md="6" className="mb-2">
                                    <FormGroup className="text-left">
                                        <Label className="text-left font-weight-bold"> Contact No </Label>
                                        <Input type="text" placeholder={userProfile.mobile_no} value={mobile_no} name="mobile_no" onChange={onChange} />
                                    </FormGroup>
                                </Col>



                            </Row>
                            <div className="text-center">
                                <Button color="info" type="submit" onClick={toggleModalClassic2} >
                                    Update
                                </Button>
                            </div>

                        </Form>

                    </ModalBody>


                </Modal>



            </div>

        </>
    );
}
const mapStateToProps = state => ({
    allClients: state.profileReducer.allClients,
    leaveRequests: state.profileReducer.leaveRequests,
    user: state.authReducer.user,
    sweetAlert: state.profileReducer.sweetAlert,
    isLoading: state.profileReducer.isLoading,
    leaveStatus: state.profileReducer.leaveStatus.message
})

export default connect(mapStateToProps, { getAllClients, updateClient, addNewClient, deleteClient, getAllLeaveRequests, changeLeaveStatus, removeSweetAlert })(LeaveRequests);






















